import React from "react";
import { CpButton, CpModal } from "canopy-styleguide!sofe";

export const EsignConfirmCloseModal = ({
  closeModal,
  showModal,
  closeModalAndOverlay,
  isTemplateMode,
  newTemplate,
  closeModalAndDelete,
}) => {
  return (
    <CpModal show={showModal} onClose={closeModal}>
      <CpModal.Header
        title={`${
          newTemplate ? "Close and Delete Draft" : "Close Without " + (isTemplateMode ? "Saving" : "Sending")
        }?`}
      />
      <CpModal.Body style={{ height: "160px" }}>
        {newTemplate
          ? "Any changes that have been made to this eSign template will not be saved and the draft will be deleted."
          : `Any changes that have been made to this eSign ${
              isTemplateMode ? "template" : "request"
            } will not be saved.`}
      </CpModal.Body>
      <CpModal.Footer>
        {newTemplate ? (
          <CpButton onClick={closeModalAndDelete}>Close and delete</CpButton>
        ) : (
          <CpButton onClick={closeModalAndOverlay}>Close without {`${isTemplateMode ? "saving" : "sending"}`}</CpButton>
        )}
        <CpButton btnType="flat" onClick={closeModal}>
          Continue editing
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
};
