import * as EsignAddSigningParcel from "./esign/esign-container.component.js";
import * as LetterAddSigningParcel from "./letters/letter-container.component.js";
import { featureEnabled } from "feature-toggles!sofe";
//eslint-disable-next-line
import SignatureEntryCard from "./signature-entry/signature-entry-card.component.js";

export const EsignSigningParcel = {
  showSigningModal: EsignAddSigningParcel.showSigningModal,
  DecoratedSigningModal: EsignAddSigningParcel.default,
  SigningParcel: EsignAddSigningParcel.SigningParcel,
  fromSigningObservable: EsignAddSigningParcel.fromSigningObservable,
  toSigningObservable: EsignAddSigningParcel.toSigningObservable,
};

export const LetterSigningParcel = {
  showSigningModal: featureEnabled("toggle_esign_letter_container")
    ? EsignAddSigningParcel.showSigningModal
    : LetterAddSigningParcel.showSigningModal,
  DecoratedSigningModal: LetterAddSigningParcel.default,
  SigningParcel: LetterAddSigningParcel.SigningParcel,
};

export async function esignModal() {
  return await import(/*  webpackChunkName: "esign-modal" */ "./esign/esign-container.component.js");
}

export async function signatureCard() {
  return await import(
    /*  webpackChunkName: "signature-entry-card" */ "./signature-entry/signature-entry-card.component.js"
  );
}
