import React, { lazy, Suspense } from "react";
import { CpButton, CpLoader } from "canopy-styleguide!sofe";

import styles from "./reminders.styles.css";
import commonStyles from "../common/common.styles.css";

const ReminderPicker = lazy(async () => {
  const { getReminderPicker } = await SystemJS.import("tasks-ui!sofe");
  return await getReminderPicker();
});

export default function Reminders({ reminders, removeReminder, setReminder, chosenDescription }) {
  return (
    <div className={styles.remindersContainerLight}>
      Reminders:
      <Suspense fallback={<CpLoader />}>
        <ReminderPicker
          reminders={reminders}
          setReminder={setReminder}
          inputClass={`${commonStyles.dottedUnderlineLight} ${commonStyles.inputPlaceholderLight} ${commonStyles.reminderWidth}`}
          style={{ marginLeft: "8px", width: "10.5rem", overflow: "hidden" }}
          placeholder={`Add a reminder`}
          valid={true}
          description={`${
            reminders.reminder_interval
              ? `${chosenDescription ? chosenDescription.shortDescription : ""} for ${
                  reminders.expiration_interval_days
                } days`
              : ``
          }`}
        />
      </Suspense>
      {reminders && reminders.reminder_interval && (
        <div style={{ marginLeft: "auto", alignSelf: "center" }}>
          <CpButton
            onClick={removeReminder}
            className="cps-cursor-pointer"
            icon="crud-trash-small"
            aria-label="delete"
          />
        </div>
      )}
    </div>
  );
}
