import React, { useState, useEffect, useMemo } from "react";
import { useCss, k } from "kremling";
import { CpButton, CpCard, CpCheckbox, CpInput, CpIcon, CpTooltip } from "canopy-styleguide!sofe";

export const SelectSignerAddEditUser = ({
  allUsers,
  addOrEditUser,
  deleteUser,
  primaryClientClientPortalUsers,
  setShowAddEditUser,
  setUser,
  user,
}) => {
  const scope = useCss(css);

  const [userFirstName, setUserFirstName] = useState(user?.first_name || "");
  const [userFirstNameError, setUserFirstNameError] = useState(false);
  const [userLastName, setUserLastName] = useState(user?.last_name || "");
  const [userLastNameError, setUserLastNameError] = useState(false);
  const [userEmail, setUserEmail] = useState(user?.email || "");
  const [isValid, setIsValid] = useState();
  const [disableEditButton, setDisableEditButton] = useState(true);
  const [inviteToClientPortal, setInviteToClientPortal] = useState(user?.sendClientPortalInvite || false);
  const [checkboxChanged, setCheckboxChanged] = useState(false);
  const [duplicateSigner, setDuplicateSigner] = useState(false);

  const disableButton =
    userFirstNameError ||
    !userFirstName ||
    userLastNameError ||
    !userLastName ||
    !userEmail ||
    !isValid ||
    duplicateSigner;

  const hasClientPortal = useMemo(
    () => primaryClientClientPortalUsers.find((clientPortalUser) => clientPortalUser.email === userEmail),
    [primaryClientClientPortalUsers, userEmail]
  );

  useEffect(() => {
    const isDuplicateSigner = allUsers.some(
      (userToCheck) =>
        userToCheck.first_name?.toLowerCase() === userFirstName?.toLowerCase() &&
        userToCheck.last_name?.toLowerCase() === userLastName?.toLowerCase() &&
        userToCheck.email?.toLowerCase() === userEmail?.toLowerCase() &&
        userToCheck?.id !== user?.id
    );
    setDuplicateSigner(isDuplicateSigner);

    if (
      checkboxChanged ||
      user?.first_name !== userFirstName ||
      user?.last_name !== userLastName ||
      user?.email !== userEmail
    ) {
      disableEditButton && setDisableEditButton(false);
    } else {
      !disableEditButton && setDisableEditButton(true);
    }
  }, [userFirstName, userLastName, userEmail, checkboxChanged]);

  const handleChange = () => {
    setInviteToClientPortal((prevState) => !prevState);
    setCheckboxChanged((prevState) => !prevState);
  };

  const blurCheckFirstName = () => {
    const userFirstNameTrimmed = userFirstName.trim();
    setUserFirstName(userFirstNameTrimmed);
    setUserFirstNameError(userFirstNameTrimmed.length === 0);
  };

  const blurCheckLastName = () => {
    const userLastNameTrimmed = userLastName.trim();
    setUserLastName(userLastNameTrimmed);
    setUserLastNameError(userLastNameTrimmed.length === 0);
  };

  return (
    <div {...scope}>
      <CpCard className="signer-container">
        <CpCard.Header>
          <div className="cps-subheader-sm">
            <CpButton
              icon="caret-large-left"
              aria-label="Back"
              className="cp-mr-8"
              onClick={() => {
                setShowAddEditUser(false), setUser("");
              }}
            />
            {user ? "Edit custom signer" : "Add new signer"}
          </div>
        </CpCard.Header>
        <CpCard.Body className="signer-body">
          <div className="cp-flex cp-mb-16 cp-flex-spread">
            <CpInput
              autoFocus
              className="cp-mr-12"
              error={userFirstNameError}
              label="First name"
              onBlur={blurCheckFirstName}
              onChange={setUserFirstName}
              required
              value={userFirstName}
            />
            <CpInput
              className=""
              error={userLastNameError}
              label="Last name"
              onBlur={blurCheckLastName}
              onChange={setUserLastName}
              required
              value={userLastName}
            />
          </div>
          <CpInput.Email
            isValid={isValid}
            label="Email"
            onChange={setUserEmail}
            required
            setIsValid={setIsValid}
            value={userEmail}
          />
          {
            <div style={{ display: "flex" }}>
              <CpCheckbox
                className="cp-mt-12"
                onChange={handleChange}
                checked={inviteToClientPortal}
                disabled={hasClientPortal}
              >
                Send client portal invite
              </CpCheckbox>
              {hasClientPortal && (
                <CpTooltip text="A client portal account already exists for this email account.">
                  <CpIcon
                    name="information-circle-open-small"
                    aria-label="Client Portal Checkbox Disabled Information"
                    className="cp-mt-8"
                    fill="var(--cp-color-app-secondary-text)"
                  />
                </CpTooltip>
              )}
            </div>
          }
          {user && (
            <CpButton
              btnType="secondary"
              onClick={() => deleteUser(user.id)}
              icon="crud-trash-large"
              className="cp-mt-16"
            >
              Delete Signer
            </CpButton>
          )}
          {duplicateSigner && <div className="duplicate-signer">Signer already exists.</div>}
        </CpCard.Body>
        <CpCard.Footer>
          {user ? (
            <CpButton
              btnType="primary"
              disabled={disableEditButton || disableButton}
              onClick={() =>
                addOrEditUser({
                  ...user,
                  first_name: userFirstName,
                  last_name: userLastName,
                  email: userEmail,
                  edited: true,
                  sendClientPortalInvite: inviteToClientPortal,
                  relationship: "Custom Signer",
                  emailType: "Email",
                })
              }
            >
              Update
            </CpButton>
          ) : (
            <CpButton
              btnType="primary"
              disabled={disableButton}
              onClick={() =>
                addOrEditUser({
                  first_name: userFirstName,
                  last_name: userLastName,
                  email: userEmail,
                  sendClientPortalInvite: inviteToClientPortal,
                })
              }
            >
              Add
            </CpButton>
          )}
          <CpButton
            btnType="flat"
            className="cp-ml-8"
            onClick={() => {
              setShowAddEditUser(false), setUser();
            }}
          >
            Cancel
          </CpButton>
        </CpCard.Footer>
      </CpCard>
    </div>
  );
};

const css = k`
  .signer-container {
    height: 38.5rem; 
  }

  .signer-body {
    height: 26.3rem;
    position: relative;
  }

  .duplicate-signer {
    color: var(--cp-color-app-error-text);
    position: absolute;
    bottom: 8px;
  }
`;
