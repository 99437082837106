import React, { useState, useContext, useMemo } from "react";
import { CpModal, CpButton, CpInput, CpRadio, CpIcon, CpLabel } from "canopy-styleguide!sofe";
import { patchSignerType, postSignerType } from "../../../signing.resource";
import { EsignContext, SigningContext } from "../../../signing-context";
import { handleError } from "src/handle-error.helper";

export const SignerTypeModal = ({ signer = {}, show, close, addSingleSigner }) => {
  const [signerName, setSignerName] = useState(signer?.name || "");
  const [userRole, setUserRole] = useState(signer?.user_role || "Client");
  const { signerTypesContext, setSignerTypesContext } = useContext(SigningContext);
  const { esignContext } = useContext(EsignContext);

  const nameError = useMemo(() => {
    return signerTypesContext.find((signerType) => signerType.name === signerName && signerType?.id !== signer?.id);
  }, [signerName, signerTypesContext, signer?.id]);

  const save = async () => {
    try {
      const signerType = await postSignerType({
        name: signerName,
        user_role: userRole,
        preset: esignContext?.template_type === "preset",
      }).toPromise();
      addSingleSigner(signerType);
      setSignerTypesContext([...signerTypesContext, signerType]);
    } catch (e) {
      handleError(e);
    }
    close();
  };

  const update = async () => {
    try {
      const savedSigner = await patchSignerType(signer.id, { name: signerName }).toPromise();
      setSignerTypesContext(
        signerTypesContext.map((signerType) => (signerType.id === savedSigner.id ? savedSigner : signerType))
      );
    } catch (e) {
      handleError(e);
    }
    close();
  };

  return (
    <CpModal width="480" show={show} onClose={close}>
      <CpModal.Header border title={"Custom Signer role"} />
      <CpModal.Body>
        <CpInput
          className="cp-mb-16"
          label="Signer title"
          error={nameError && "Signer role already exists"}
          required
          value={signerName}
          onChange={setSignerName}
        />

        <>
          <CpLabel htmlFor="signer-type">Signer Type</CpLabel>
          <CpRadio
            id="signer-type"
            className="cp-mt-16"
            onChange={setUserRole}
            value={userRole}
            inline
            disabled={signer?.id}
            name="Signer Type"
          >
            <CpRadio.Item id="Client">Client</CpRadio.Item>
            <CpRadio.Item id="TeamMember">Team Member</CpRadio.Item>
          </CpRadio>
        </>

        <div className="cp-mv-16 cp-caption cp-color-app-inactive-text" style={{ fontStyle: "italic" }}>
          <CpIcon name="urgent-open-small" className="cp-mr-8" />
          Signers added during template creation will be assigned to recipients when the template is applied to an eSign
          request.
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          disabled={nameError || !signerName?.length}
          className="cp-mr-12"
          btnType="primary"
          onClick={signer?.id ? update : save}
        >
          Save
        </CpButton>
        <CpButton btnType="flat" onClick={close}>
          Close
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
};
