import React from "react";

export function isClient(context) {
  return context.loggedInUser.role === "Client";
}

export function convertDateToString(dateString) {
  const signDateObj = new Date(dateString);
  return `${signDateObj.getMonth() + 1}/${signDateObj.getDate()}/${signDateObj.getFullYear()}`;
}

// This offset is to account for the difference between the coordinate point
// and the visual styling of the signature box.
// Should equal the padding of `.filledSigningObject` css class.
const SIGNINGOBJ_PADDING_OFFSET = 8;

// This offset is to account for the difference between the drag handle and the start of the actual signature placement.
// Should equal the padding of .filledSigningObject css class.
// const DRAG_HANDLE_WIDTH = 14;

export function remapSigningLocationsFromRatio(signingLocations, pageNum, { width, height }) {
  // remaps signingLocations for a specific page
  return signingLocations.map((loc) => {
    if (loc.page === pageNum) {
      // Assumption: 0-1 inclusive reserved as range for ratios
      let { x, y } = loc;
      const xIsRatio = x - 1 < 0;
      const yIsRatio = y - 1 < 0;

      const newX = (xIsRatio ? x * width : x) - SIGNINGOBJ_PADDING_OFFSET;
      const newY = (yIsRatio ? y * height : y) - SIGNINGOBJ_PADDING_OFFSET;
      return { ...loc, x: newX, y: newY };
    } else {
      return loc;
    }
  });
}

export function remapAllSigningLocationsFromRatio(signingLocations, documentSizes) {
  // remap all signingLocations
  return signingLocations.map((loc) => {
    if (!documentSizes[loc?.page]) {
      return;
    }
    const { width, height } = documentSizes[loc.page];
    // Assumption: 0-1 inclusive reserved as range for ratios
    let { x, y } = loc;
    const xIsRatio = x - 1 < 0;
    const yIsRatio = y - 1 < 0;

    const newX = (xIsRatio ? x * width : x) - SIGNINGOBJ_PADDING_OFFSET;
    const newY = (yIsRatio ? y * height : y) - SIGNINGOBJ_PADDING_OFFSET;
    return { ...loc, x: newX, y: newY };
  });
}

export function remapSigningLocationsToRatio(signingLocations, documentSizes) {
  return signingLocations?.map((loc) => {
    const { width, height } = documentSizes[loc.page];
    let { x, y } = loc;

    const newX = (x + SIGNINGOBJ_PADDING_OFFSET) / width;
    const newY = (y + SIGNINGOBJ_PADDING_OFFSET) / height;
    return { ...loc, x: newX, y: newY };
  });
}

export const searchHighlight = (string, term) => {
  if (!term || !string) return string;
  term = term.toLowerCase();
  const stop = string.toLowerCase().indexOf(term);
  if (stop === -1) return string;
  const bold = string.slice(stop, stop + term.length);
  const nonBold = string.slice(0, stop);
  const rest = string.slice(stop + term.length, string.length);
  return (
    <span>
      {nonBold}
      <strong className="cps-color-primary">{bold}</strong>
      {searchHighlight(rest, term)}
    </span>
  );
};

//this is the same classname that the CpModal from canopy styleguide uses to hide background scroll
const hideScrollbarClassName = "--esign-hide-scroll-bar";

export const __openModal = (hideScroll = true) => {
  // run when esign component mounts
  if (hideScroll) {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.classList.add(hideScrollbarClassName);
    document.body.style.marginRight = `${scrollbarWidth / 10}rem`;
  }
};
export const __closeModal = () => {
  //run when esign component unmounts
  document.body.classList.remove(hideScrollbarClassName);
  document.body.style.marginRight = "initial";
};

export function userMatchesSigningLocation(signingLocation, user) {
  if (signingLocation.signer_id) {
    return signingLocation.signer_id === user.id && signingLocation.signatory_user_id === user.user_id;
  } else {
    return signingLocation.signatory_user_id === user.id;
  }
}

export function capitalizeFirstLetter(str) {
  if (!str) return str;
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

export const getContactType = (user) => {
  return user?.contact_type === "primary" ? "Primary Contact" : capitalizeFirstLetter(user?.contact_type);
};

export const transformUsersForSelect = (user, signerTypes, isNewCrm) => {
  const prepend = user?.isSelf ? "(You) " : "";
  const overline = isNewCrm ? { overline: getContactType(user) } : {};
  return {
    id: user.id,
    name: `${prepend}${user.name}`,
    subName: user?.email,
    disabled: !!signerTypes?.find((type) => type?.assignedTo?.id === user?.id),
    ...overline,
  };
};

export const mapSigningLocationsForTemplate = (location) => ({
  page: location.page,
  x: location.x,
  y: location.y,
  type: location.type,
  signer_type_id: location.signer_type_id,
  // id: location.id, //backend does not allow
});

export const groupDataForSelect = (groupKeys) => {
  return Object.keys(groupKeys).map((key) => ({
    id: key,
    name: groupKeys[key]?.name,
    key: groupKeys[key]?.key,
    ...(groupKeys[key]?.icon && { icon: groupKeys[key].icon }),
  }));
};
