import React from "react";
import styles from "../esign-modal.styles.css";
import { CpButton } from "canopy-styleguide!sofe";

export const FooterBar = ({
  documentCompleted,
  signingRequestIsValid,
  completeDocumentAsClient,
  reviewDocument,
  esignSent,
}) => {
  return (
    <div className={`${styles.footer} ${!signingRequestIsValid && styles.hidden}`}>
      <div className="cp-flex-center">
        <CpButton
          btnType="primary"
          disabled={!signingRequestIsValid || documentCompleted || esignSent}
          onClick={completeDocumentAsClient}
        >
          Send
        </CpButton>
        <CpButton className="cp-ml-16" btnType="flat" onClick={reviewDocument}>
          Review
        </CpButton>
      </div>
    </div>
  );
};
