import React from "react";
import PropTypes from "prop-types";
import canopyUrls from "canopy-urls!sofe";
import styles from "../signing-modal.styles.css";

export default class ClientRequestSuccessModal extends React.Component {
  static propTypes = {
    clientId: PropTypes.string.isRequired,
    resolutionCaseId: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    const { clientId, resolutionCaseId } = this.props;

    return (
      <div className={`${styles.sentDialog} cps-modal`}>
        <div className="cps-modal__screen"></div>
        <div className="cps-modal__dialog cps-card">
          <div className={`${styles.sentDialogImg}`}>
            <img src="https://cdn.canopytax.com/static/workflow-ui/sending-illustration.svg" />
          </div>
          <div className="cps-text-center cps-animate-fade">
            <div className={`${styles.sentDialogHeader}`}>Your client request has been sent</div>
            <div className={`${styles.sentDialogSub} cps-margin-top-24`}>
              <a
                className="cps-color-primary"
                onClick={this.redirectToResolutionCaseDashboard.bind(null, clientId, resolutionCaseId)}
              >
                Return to resolution cases dashboard
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  redirectToResolutionCaseDashboard = (clientId, resolutionCaseId) => {
    this.props.closeModal();
    canopyUrls.navigateToUrl(`/#/taxes/client/${clientId}/engagements/${resolutionCaseId}/summary`);
  };
}
