import React from "react";
import PropTypes from "prop-types";
import styles from "./kba-third-fail.styles.css";
import { CpIcon, CpLoader } from "canopy-styleguide!sofe";
import { noop } from "lodash";
import { loadUploadFilesHelper } from "docs-ui!sofe";
import UploadFileWell from "./upload-file-well.component";
import { warningToast } from "toast-service!sofe";
import { getFileExtensionFromName, NUMBERS_MAP } from "./kba-file-upload.helpers";

export default class KbaThirdFail extends React.Component {
  static propTypes = {
    closeSigningExperience: PropTypes.func,
    clientId: PropTypes.string,
    submitManualSignature: PropTypes.func,
    taxPrepManualSign: PropTypes.bool,
    document: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      originalPdfDownloadUrl: PropTypes.string,
      originalPdfUrl: PropTypes.string,
      kbaAttemptsAllowed: PropTypes.number,
    }).isRequired,
  };
  state = {
    uploadFile: null,
    upload: noop,
    tooManyFilesError: false,
    fileUploading: false,
  };
  componentDidMount() {
    loadUploadFilesHelper().then(({ uploadFilesAsync: upload }) => {
      this.setState({ upload });
    });
  }

  addFileForUpload = (files) => {
    if (files.length > 1) {
      return warningToast(`Please select one file to upload`);
    }

    const file = files[0];

    //Edge does not support new File(), so Blob() is required to change the name of the file
    const blobFile = new Blob([file], { type: file.type });
    blobFile.name = `${this.props.document.title} (manually signed).${getFileExtensionFromName(file.name)}`;
    blobFile.lastModifiedDate = file.lastModifiedDate;

    this.setState({ uploadFile: blobFile });
  };

  removeUploadFile = () => {
    this.removeBtn.blur();
    this.setState({ uploadFile: null });
  };

  handleUploadFile = () => {
    const { taxPrepManualSign, hideEsignRequest } = this.props;
    this.setState({ fileUploading: true });
    this.state.upload([this.state.uploadFile], {
      destinationFolder: { id: `CON${this.props.clientId}` },
      inbox: true,
      hidden: taxPrepManualSign || hideEsignRequest,
      uploadedFilesCallback: ([uploadedFile]) => {
        this.setState({ fileUploading: false });
        this.props.submitManualSignature(uploadedFile);
      },
    });
  };
  render() {
    const isReadyForUpload = !!this.state.uploadFile;
    const { title, originalPdfDownloadUrl, originalPdfUrl, kbaAttemptsAllowed } = this.props.document
      ? this.props.document
      : {};
    const { taxPrepManualSign } = this.props;
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
        className="cp-p-24"
      >
        <div style={{ textAlign: "center" }} className="cp-mb-16">
          <h3 className="cp-subheader cp-wt-bold">
            {taxPrepManualSign ? "Sign Authorization Forms" : "Answers Did Not Pass Authentication"}
          </h3>
          <div className="cp-body cp-mb-24">
            {taxPrepManualSign
              ? "Your practitioner has turned off electronic signatures. Please download, sign and upload the forms."
              : `After ${NUMBERS_MAP[kbaAttemptsAllowed]} failed verification attempts, the IRS requires taxpayers to submit a handwritten signature instead of an esignature.`}
          </div>

          <div className={`${styles.uploadContainer} cps-well`}>
            <div className={`${styles.downloadBanner} cp-subheader-sm cp-wt-bold cps-color-white cps-bg-color-gray`}>
              {isReadyForUpload
                ? "Upload file"
                : taxPrepManualSign
                ? "Authorization Forms"
                : "Please download, sign, and upload file"}
            </div>
            <div className={`cp-p-16`}>
              <div className={`${styles.fileCard} cps-bg-color-white`}>
                <div className="cp-ellipsis" style={{ display: "flex", alignItems: "center" }}>
                  {isReadyForUpload ? (
                    <span className="cp-mr-4" aria-hidden={true}>
                      <CpIcon name="misc-cloud-up-arrow" fill="var(--cps-color-vibrant-ocean)" />
                    </span>
                  ) : (
                    <span className="cp-mr-4" aria-hidden={true}>
                      <CpIcon name="file-pdf" fill="var(--cps-color-mandy)" />
                    </span>
                  )}
                  <div className="cp-body cp-ellipsis">{isReadyForUpload ? this.state.uploadFile.name : title}</div>
                </div>
                {isReadyForUpload ? (
                  <div className={`${styles.fileActionSet}`}>
                    <button
                      ref={(el) => {
                        this.removeBtn = el;
                      }}
                      type="button"
                      onClick={this.removeUploadFile}
                    >
                      <span aria-hidden={true}>
                        <CpIcon name="close-large " />
                      </span>
                      <span className="cps-hide-visually">Remove {this.state.uploadFile.name}</span>
                    </button>
                  </div>
                ) : (
                  <div className={`${styles.fileActionSet}`}>
                    <a
                      href={`${originalPdfDownloadUrl}`}
                      download={`${originalPdfDownloadUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span aria-hidden={true}>
                        <CpIcon name="af-open-down" />
                      </span>
                      <span className="cps-hide-visually">Download {title}</span>
                    </a>
                    <a href={`${originalPdfUrl}`} target="_blank" rel="noopener noreferrer">
                      <span aria-hidden={true}>
                        <CpIcon name="misc-printer" />
                      </span>
                      <span className="cps-hide-visually">Print {title}</span>
                    </a>
                  </div>
                )}
              </div>

              {!isReadyForUpload && (
                <React.Fragment>
                  <div
                    className="cp-mv-24"
                    style={{ maxWidth: "80%", margin: "0 auto", borderBottom: "solid 1px var(--cps-color-monsoon)" }}
                  />
                  <UploadFileWell uploadFiles={this.addFileForUpload} />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        <div className={`${styles.submitButtons}`}>
          <button
            type="button"
            onClick={this.handleUploadFile}
            className="cps-btn +primary"
            disabled={!isReadyForUpload}
          >
            {this.state.fileUploading ? <CpLoader /> : "Upload and send"}
          </button>
          <button
            type="button"
            onClick={() => this.props.closeSigningExperience("retry")}
            className="cps-btn +secondary"
          >
            I'll come back later
          </button>
        </div>
      </div>
    );
  }
}
