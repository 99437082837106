import { useState, useEffect, useContext } from "react";
import { findIndex, remove, cloneDeep } from "lodash";
import { postUser, patchSigner, deleteSigner } from "./signing.resource.js";
import { SigningContext } from "./signing-context";
import { esignTemplatesFeatureBubble, userRoles } from "./constants.js";
import { forkJoin } from "rxjs";
import { handleError } from "src/handle-error.helper.js";
import { successToast } from "toast-service!sofe";
import { useFeatureBubble } from "feature-toggles!sofe";

export function useCreateOrEditCustomSigner({ clientId, afterSave }) {
  const { signersContext, setSignersContext } = useContext(SigningContext);

  const [user, setUser] = useState(""); //this is the user to be edited
  const [updatedUser, setUpdatedUser] = useState(null);

  const showEsignTemplates = useFeatureBubble(esignTemplatesFeatureBubble);

  useEffect(() => {
    if (!updatedUser) return;

    const isEmailChanged = user?.email ? user.email !== updatedUser.email : true;
    if (updatedUser?.edited && !isEmailChanged) {
      // to edit name only
      const sub = patchSigner(updatedUser).subscribe((editedUser) => {
        setUserData(true, editedUser);
      }, handleError);

      return () => sub?.unsubscribe();
    }

    if (user?.email && isEmailChanged) {
      const sub = forkJoin({
        deleteSigner: deleteSigner(updatedUser.id, clientId),
        newUser: postUser(updatedUser),
      }).subscribe(({ newUser }) => {
        setUserData(true, newUser);
      }, handleError);

      return () => sub?.unsubscribe();
    }

    const sub = postUser(updatedUser).subscribe((newUser) => setUserData(false, newUser), handleError);

    return () => sub?.unsubscribe();
  }, [updatedUser]);

  const setUserData = (edit, newUser = null) => {
    //if we are running a POST we get a new user back and add some props
    if (newUser) {
      newUser.role = userRoles.client;
      newUser.canEdit = true;
      newUser.sendClientPortalInvite = updatedUser.sendClientPortalInvite;
      newUser.relationship = "Custom Signer";
      newUser.clientType = "Other Clients"; // custom signers should always be "Other Clients"
      newUser.emailType = "Email";
    }
    //if we are patching or deleting we need to splice the list, and set the updatedUser to update existing signing fields
    if (edit) {
      const updatedUsersList = cloneDeep(signersContext);
      const userIndex = findIndex(updatedUsersList, (client) => client.id === updatedUser.id);
      updatedUsersList.splice(userIndex, 1, newUser ? newUser : updatedUser);
      setSignersContext(updatedUsersList);
    }
    //if we are POSTING only we should just add the new user to the top of the list
    if (!edit && newUser) {
      const combinedSignerList = [newUser, ...signersContext];
      setSignersContext(combinedSignerList);
    }
    afterSave(newUser ? newUser : updatedUser);
    setUpdatedUser();
    setUser();
  };

  const addOrEditUser = (editedUser) => {
    if (!editedUser.client_id) editedUser.client_id = Number(clientId);
    setUpdatedUser(editedUser);
  };

  const deleteUser = async (userId, user) => {
    try {
      await deleteSigner(userId, clientId).toPromise();
      const updatedUsersList = cloneDeep(signersContext);
      remove(updatedUsersList, (client) => client.id === userId);
      setSignersContext(updatedUsersList);
      setUser();
      setUpdatedUser();
      showEsignTemplates && successToast(`Custom signer ${user?.name ? `"${user.name}"` : ""} was deleted.`);
    } catch (err) {
      handleError(err);
    }
  };

  return { addOrEditUser, deleteUser, setUser };
}
