import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

export default function PanelDragWrapper({ isTemplateMode, signatureElement, isTeamMember, signer, ...props }) {
  const [{ opacity }, drag, preview] = useDrag(
    () => ({
      type: "dragField",
      item: {
        ...signatureElement,
        isTeamMember,
        border: isTeamMember ? "var(--cp-color-pill-team-text)" : "var(--cp-color-app-primary)",
        color: isTeamMember ? "var(--cp-color-pill-team-bg)" : "var(--cp-color-pill-bg)",
        ...(!isTemplateMode && { signatory_user_id: signer.user_id || signer.id }),
        ...(!isTemplateMode && !isTeamMember && signer.user_id && { signer_id: signer.id }),
        ...(isTemplateMode && { signer_type_id: signer.id }),
        ...(isTemplateMode && { signer_type_name: signer.name }),
        role: signer?.role || signer?.user_role || "Client",
        value: null,
        font: null,
        completed_at: null,
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
      previewOptions: {
        anchorX: 0,
        anchorY: 0,
      },
    }),
    [signatureElement, signer, isTeamMember]
  );

  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, [preview, signatureElement, signer]);

  const dragElementStyles = {
    opacity: opacity,
    cursor: "move",
    width: "50%",
  };
  //TODO: draggable fields from panel
  return (
    <>
      <div ref={drag} style={dragElementStyles}>
        {props.children}
      </div>
    </>
  );
}
