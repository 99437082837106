import React, { useEffect, useMemo, useRef, useContext } from "react";
import { signingFieldTypes, typeIconMatch } from "../constants.js"; //dragTypes
import { convertDateToString, getContactType, userMatchesSigningLocation } from "../signing-modal.helper.js";
import { CpSelectSingle } from "canopy-styleguide!sofe";
import { SignerPill } from "../esign/page-layout-components/signer-pill.component";
// import CompletedField from "./completed-field.component.js";
import { SigningContext } from "../signing-context.js";

const textFields = {
  date: "Date",
  initial: "Initial",
  signature: "Recipient Signature",
  you: "You",
  yourInitial: "Your Initial",
  yourSignature: "Your Signature",
};

export default function NewDraggableSigningField({
  allUsers,
  clientCollaborators,
  signingObject,
  deleteSigningField,
  openSelectSignerModal,
  context,
  showSignerDropdown,
  isDragging,
  setSigner,
  openSignatureEntryModal,
  signingLocationsAreDraggable,
}) {
  const signDate = signingObject.completed_at;
  const forCurrentUser = userMatchesSigningLocation(signingObject, context.loggedInUser);
  const client = allUsers.find((user) => userMatchesSigningLocation(signingObject, user));
  //TODO: do we need the clientCollaborators[signingObject.signatory_user_id] check?
  const clientName = client?.name || clientCollaborators[signingObject.signatory_user_id];
  const isSignatureObject = signingObject.type === signingFieldTypes.SIGNATURE;
  const isInitialObject = signingObject.type === signingFieldTypes.INITIAL;
  const isDateObject = signingObject.type === signingFieldTypes.DATE;
  const draggableRef = React.useRef();
  const hasBeenSigned = signingObject.hasBeenSigned || signingObject.signed;
  const practitionerSignature = forCurrentUser && signingObject.isTeamMember;
  const displayValue = signingObject.value && signDate && (isDateObject || hasBeenSigned || practitionerSignature);
  const scrollRef = useRef();
  const { isNewCrm, scrollTo, setScrollTo, getSelectedSigners, signerTypesContext } = useContext(SigningContext);
  const selectedSigners = getSelectedSigners();

  const dragStyles = {
    opacity: isDragging ? 0 : 1,
  };

  const deleteField = () => {
    deleteSigningField(signingObject.id);
  };

  useEffect(() => {
    if (showSignerDropdown?.id === signingObject.id) {
      draggableRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      //TODO better track for when scroll ends
      const time = setTimeout(() => {
        openSelectSignerModal({ target: draggableRef.current }, signingObject);
      }, 600);
      return () => clearTimeout(time);
    }
  }, [showSignerDropdown]);

  const renderText = useMemo(() => {
    if (signingObject?.signer_type_name && !signingObject?.signatory_user_id) return signingObject.signer_type_name;
    if (displayValue) {
      return isDateObject ? convertDateToString(signDate) : signingObject.value;
    }
    if (forCurrentUser && signingLocationsAreDraggable) return textFields.you;
    if (forCurrentUser && !signingLocationsAreDraggable && isSignatureObject) return textFields.yourSignature;
    if (forCurrentUser && !signingLocationsAreDraggable && isInitialObject) return textFields.yourInitial;

    if (!forCurrentUser && clientName) return clientName;
    if (signingObject.signer_type?.name) return signingObject.signer_type.name;
    if (isSignatureObject) return textFields.signature;
    if (isInitialObject) return textFields.initial;
    return textFields.date;
  }, [
    signingObject.value,
    signingObject.signer_type_id,
    signingObject.signatory_user_id,
    signDate,
    clientName,
    hasBeenSigned,
  ]);

  const openOnDrop = (toggle, e) => {
    toggle(e);
  };
  const assignField = (user) => {
    setSigner(user, signingObject);
  };

  useEffect(() => {
    if (scrollTo?.id === signingObject?.id) {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      setScrollTo(null);
    }
  }, [scrollTo]);

  const signerAlreadyAssigned = (signer) =>
    signerTypesContext?.find((type) => type?.assignedTo?.id === signer?.id && signingObject.signer_type_id !== type.id);

  const signerTypeName = (signer) => {
    const type = signerTypesContext?.find((type) => type?.assignedTo?.id === signer?.id);
    return type?.name;
  };
  const signerTypeForObject =
    signingObject?.signer_type_id && signerTypesContext?.find((type) => type?.id === signingObject?.signer_type_id);

  return (
    <div style={dragStyles} ref={scrollRef}>
      <CpSelectSingle
        data={selectedSigners}
        onChange={assignField}
        contentWidth="md"
        transformData={(signer) => ({
          ...signer,
          name: `${signer?.isSelf ? "(You) " : ""}${signer.name} ${
            signerTypeName(signer) ? `(${signerTypeName(signer)})` : ""
          }`,
          ...(isNewCrm
            ? {
                overline: getContactType(signer),
              }
            : {}),
          icon: "shape-circle-closed",
          iconColor:
            signer?.user_role === "TeamMember" || signer?.role === "TeamMember"
              ? "var(--cp-color-pill-team-text)"
              : "var(--cp-color-default-badge-bg)",
          isTeamMember: signer?.user_role === "TeamMember" || signer?.role === "TeamMember",
          disabled: !!signerAlreadyAssigned(signer),
        })}
        disabled={!signingLocationsAreDraggable}
        renderTrigger={({ open, toggle, ref }) => (
          <SignerPill
            dragHandle={signingLocationsAreDraggable}
            onClick={(e) =>
              !forCurrentUser
                ? openOnDrop(toggle, e)
                : !isDateObject && !signingObject?.signed && openSignatureEntryModal()
            }
            icon={!displayValue && typeIconMatch[signingObject?.type]}
            teamMember={
              forCurrentUser || signerTypeForObject?.user_role === "TeamMember" || signingObject?.isTeamMember
            }
            text={renderText}
            onRemove={signingLocationsAreDraggable && deleteField}
            ref={ref}
            open={open}
            font={(displayValue || hasBeenSigned) && !isDateObject && signingObject?.font}
            type={signingObject?.type}
            staticLocation={
              signingObject?.esigning_location_id && signingObject.signed && !signingLocationsAreDraggable
            }
            signedLocation={(displayValue || hasBeenSigned) && signingObject?.value && signingObject?.completed_at}
          />
        )}
      />
    </div>
  );
}
