import React, { useMemo } from "react";
import { useCss, k } from "kremling";
import { searchHighlight, userMatchesSigningLocation } from "../signing-modal.helper";
import { CpArea, CpButton, CpHeader, CpIcon } from "canopy-styleguide!sofe";
import { clientTypes, userRoles } from "../constants.js";

export const SelectSignerList = ({
  allUsers,
  context,
  searchTerm,
  setShowAddEditUser,
  setSigner,
  setUser,
  signingField,
  signingLocations,
  type,
}) => {
  const scope = useCss(css);

  const currentlySelectedSigners = useMemo(
    () =>
      allUsers
        .filter((user) =>
          signingLocations?.find(
            (location) =>
              userMatchesSigningLocation(location, user) && location.signatory_user_id !== context.loggedInUser.id
          )
        )
        .sort((a, b) => b.name.localeCompare(a.name)),
    [allUsers, type, signingLocations]
  );

  const componentUsers = useMemo(() => {
    let filteredUsers = [];

    filteredUsers = allUsers
      .filter((user) => {
        const isSelected = signingLocations?.find(
          (location) =>
            userMatchesSigningLocation(location, user) && location.signatory_user_id !== context.loggedInUser.id
        );
        if (type === clientTypes.currentlySelected) return isSelected;
        return !isSelected;
      })
      .filter(
        (user) =>
          (user.clientType === type || type === clientTypes.currentlySelected) && user.id !== context.loggedInUser.id
      )
      .sort((a, b) => currentlySelectedSigners.indexOf(b) - currentlySelectedSigners.indexOf(a));

    return filteredUsers;
  }, [allUsers, type, signingLocations]);

  const handleClickEdit = (e, user) => {
    e.stopPropagation();
    setShowAddEditUser(true), setUser(user);
  };
  if (type === clientTypes.currentlySelected && componentUsers?.length < 1) return null;

  return (
    <div {...scope}>
      <CpHeader className="cp-pt-12 cp-ph-0" level={1} border={false}>
        <div className="section-header">
          <CpIcon className="cp-mr-4" name={type === clientTypes.teamMember ? "person-people" : "person"} />
          {type}
        </div>
        <hr className="header-line" />
      </CpHeader>
      {componentUsers && componentUsers.length > 0 ? (
        componentUsers.map((user) => (
          <CpArea
            key={user.id}
            onClick={() =>
              setSigner(
                user.user_id ? user.user_id : user.id,
                user.name,
                signingField.id,
                user.user_id ? user.id : null,
                user.role
              )
            }
          >
            <div className="cp-flex-spread-center cp-p-8">
              <div className="ellipsis-wrapper">
                <div className="cps-body cps-ellipsis">{searchHighlight(user.name, searchTerm)}</div>
                <div className="cps-caption cps-ellipsis">{searchHighlight(user.email, searchTerm)}</div>
              </div>
              {user?.canEdit && (
                <div className="user-icon">
                  {user.role === userRoles.client && (
                    <CpButton icon="crud-pencil" aria-label="Edit" onClick={(e) => handleClickEdit(e, user)} />
                  )}
                </div>
              )}
            </div>
          </CpArea>
        ))
      ) : (
        <div className="empty-list">
          {type === clientTypes.teamMember ? "No team members with client access" : "Currently no clients"}
        </div>
      )}
    </div>
  );
};

const css = k`
  .section-header {
    font-size: 1.4rem !important;
    line-height: 2.0rem !important;
    font-weight: 700;
    white-space: nowrap;
  }

  .ellipsis-wrapper {
    min-width: 40%;
  }
  
  .header-line {
    margin-left: 4px;
    background-color: var(--cp-color-card-border)
  }

  .user-icon {
    width: 20%;
    text-align: right;
  }

  .empty-list {
    font-size: 1.4rem !important;
    line-height: 2.0rem !important;
    font-style: italic;
    color: var(--cp-color-priority-disabled-text);
    padding: 1rem 0
  }
`;
