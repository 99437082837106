exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* client-request.styles.css */\n\n.client-request-styles__clientRequestContainer--20lJA {\n  display: flex;\n  display: -webkit-flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-content: center;\n  align-items: flex-start;\n  padding-top: 1.6rem;\n  height: calc(100vh - 160px);\n}\n\n.client-request-styles__clientRequestElement--enw33 {\n  width: 32rem;\n  border-top: solid 1px #5f686d;\n  padding: 2rem 2.4rem;\n  color: #fff;\n}\n\n.client-request-styles__clientRequestElementLight--BP4Ax {\n  width: 32rem;\n  border-top: solid 1px var(--cp-color-input-border);\n  padding: 2rem 2.4rem;\n}\n\n.client-request-styles__clientRequestScroll--26ExG {\n  min-height: 10rem;\n  max-height: 15rem;\n}\n\n.client-request-styles__clientRequestSubmit--134x4 {\n  max-height: 7.5rem;\n}\n", ""]);
// Exports
exports.locals = {
	"clientRequestContainer": "client-request-styles__clientRequestContainer--20lJA",
	"clientRequestElement": "client-request-styles__clientRequestElement--enw33",
	"clientRequestElementLight": "client-request-styles__clientRequestElementLight--BP4Ax",
	"clientRequestScroll": "client-request-styles__clientRequestScroll--26ExG",
	"clientRequestSubmit": "client-request-styles__clientRequestSubmit--134x4"
};