import React from "react";
import styles from "./kba-signer-header.styles.css";

const SignerHeader = () => (
  <h3 className={`cps-subheader ${styles.kbaSignerHeader}`}>
    <span className={`${styles.kbaIcon}`} aria-hidden="true"></span>
    Signer Authentication
  </h3>
);

export default SignerHeader;
