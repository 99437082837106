import React from "react";
import styles from "../kba.styles.css";
import { CpIcon, CpTooltip } from "canopy-styleguide!sofe";
import CreditManageWell from "./credit-manage-well.component.js";

export default function Kba({ setKbaEnabled, kbaEnabled = false, creditsSubtracted = 0, creditsUsedToDate, credits }) {
  const toggleKbaEnabled = (e) => {
    setKbaEnabled(e.target.checked);
  };

  const hasPurchasedCreditsBefore = creditsUsedToDate > 0 || credits > 0; // User has purchased credits before (even if credits have since been depleted)
  const showCreditsManageWell = kbaEnabled && hasPurchasedCreditsBefore; // If KBA is toggled on and if credits have been purchased before, show CreditManageWell

  return (
    <div className={styles.kba} style={{ width: "100%" }}>
      <div className={styles.flexSpread}>
        {!hasPurchasedCreditsBefore && (
          <span className="cp-flex-center" data-testid="no-kba">
            <CpIcon className="cp-mr-8" name="misc-kba-shield" />
            <span className="cp-body cp-wt-bold">Signer Authentication</span>
          </span>
        )}
        {hasPurchasedCreditsBefore && (
          <label data-testid="kba+" className={`cps-toggle ${kbaEnabled ? "active" : ""}`} htmlFor="enable-recipient">
            <input id="enable-recipient" type="checkbox" checked={kbaEnabled} onChange={toggleKbaEnabled} />
            <span></span>
            <span className="cp-body cp-wt-bold cp-ml-8">Signer Authentication</span>
          </label>
        )}
        <InfoTooltip icon="information-circle-open-large" />
      </div>
      {showCreditsManageWell && <CreditManageWell credits={credits} creditsSubtracted={creditsSubtracted} />}
      {credits - creditsSubtracted < 0 && kbaEnabled && (
        <span className={styles.caveat}>
          More credits are required. Credits can be purchased by calling:{" "}
          <strong className="cps-wt-bold">1-855-616-7305</strong>
        </span>
      )}
    </div>
  );
}

export const InfoTooltip = ({ icon, fill }) => (
  <CpTooltip
    position="right-start"
    text={
      <div id="kba_desc">
        <h4 className={styles.kbaHeader}>Signer Authentication</h4>
        Enabling recipient ID verification will require tax-payer recipients to authenticate their identity through
        knowledge-based authentication before signing a document.
        <p className="cp-wt-bold" style={{ marginBottom: "7px" }}>
          Required by IRS for eSigning forms:
        </p>
        <p className="wells" style={{ margin: "0" }}>
          <span className={styles.kbaChip}>8878</span>
          <span className={styles.kbaChip}>8879</span>
        </p>
        <p className={styles.contactNow}>
          You can purchase credits in Account Management or by calling a product specialist at{" "}
          <a href="tel:18556167305" className={styles.phone}>
            1-855-616-7305
          </a>
        </p>
      </div>
    }
  >
    <CpIcon name={icon} fill={fill}></CpIcon>
  </CpTooltip>
);
