export function getFileExtensionFromName(fileName) {
  const splitName = fileName.split(".");
  if (splitName.length < 2) {
    return "";
  }
  return splitName[splitName.length - 1];
}

export const NUMBERS_MAP = {
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
};
