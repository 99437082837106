import React, { useState, useEffect } from "react";
import styles from "../signing-modal.styles.css";

export const PreviewDocument = ({ svg }) => {
  const [dimensions, setDimensions] = useState({ width: 816, height: 1056 });

  useEffect(() => {
    const img = new Image();
    img.onload = function () {
      setDimensions({ width: this.width, height: this.height });
    };
    img.src = svg;
  }, [svg]);

  return (
    <div
      className={`${styles.document}  cps-depth-2`}
      style={{ width: `${dimensions.width}px`, height: `${dimensions.height}px` }}
    >
      <img src={svg} />
    </div>
  );
};
