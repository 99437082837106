exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".draggable-menu-item-styles__draggableElementsRow--3t8Wf {\n  flex: 0 1 auto;\n  width: 320px;\n  border-top: solid 1px #5F686D;\n  margin: 20px 10px 15px;\n  padding-top: 20px;\n}\n\n.draggable-menu-item-styles__draggableElementsContainer--okihT {\n  display: flex;\n  display: -webkit-flex;\n  flex-flow: row wrap;\n  justify-content: center;\n  align-content: flex-start;\n  align-items: center;\n}\n\n.draggable-menu-item-styles__draggableElement--ma-iK {\n  flex: 0 1 auto;\n  display: flex;\n  justify-content: flex-start;\n  align-content: center;\n  align-items: center;\n  margin: 8px;\n  height: 56px;\n  background-color: white;\n  border-radius: 5px;\n  -webkit-user-select: all;\n  user-select: none;\n  width: calc(50% - 18px);\n  padding: 8px;\n}\n\n.draggable-menu-item-styles__dragHandleLargeGreen--2wBru {\n  border: 2px dotted var(--cps-color-green);\n  border-top-style: none;\n  border-bottom-style: none;\n  height: 20px;\n  width: 6px;\n}\n\n.draggable-menu-item-styles__dragHandleLargeBlue--2dVjU {\n  border: 2px dotted var(--cps-color-info);\n  border-top-style: none;\n  border-bottom-style: none;\n  height: 20px;\n  width: 6px;\n}\n\n.draggable-menu-item-styles__iconSpace--7eHV_ {\n  margin: auto 8px;\n}", ""]);
// Exports
exports.locals = {
	"draggableElementsRow": "draggable-menu-item-styles__draggableElementsRow--3t8Wf",
	"draggableElementsContainer": "draggable-menu-item-styles__draggableElementsContainer--okihT",
	"draggableElement": "draggable-menu-item-styles__draggableElement--ma-iK",
	"dragHandleLargeGreen": "draggable-menu-item-styles__dragHandleLargeGreen--2wBru",
	"dragHandleLargeBlue": "draggable-menu-item-styles__dragHandleLargeBlue--2dVjU",
	"iconSpace": "draggable-menu-item-styles__iconSpace--7eHV_"
};