import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { dragTypes } from "../constants.js";
import { userMatchesSigningLocation } from "../signing-modal.helper";

export const DragWrapper = ({
  closeSelectSignerModal,
  signingObject,
  clientCollaborators,
  allUsers,
  canDrag,
  ...props
}) => {
  const client = allUsers.find((user) => userMatchesSigningLocation(signingObject, user));
  //TODO: do we need the clientCollaborators[signingObject.signatory_user_id] check?
  const clientName = client?.name || clientCollaborators[signingObject.signatory_user_id];
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: dragTypes.SIGNING_FIELD,
      item: { ...signingObject, name: clientName },
      canDrag: canDrag,
      isDragging: (monitor) => {
        return monitor.getItem().id === signingObject.id;
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [signingObject]
  );

  useEffect(() => {
    if (isDragging) closeSelectSignerModal();
  }, [isDragging]);

  useEffect(() => {
    if (!signingObject.value && !signingObject.completed_at) {
      preview(getEmptyImage(), {
        captureDraggingState: true,
      });
    } else {
      preview();
    }
  }, [preview, signingObject, canDrag]);

  const dragStyles = {
    marginTop: signingObject.y,
    marginLeft: signingObject.x,
    cursor: canDrag ? "move" : null,
    position: "absolute",
    zIndex: 10000,
  };

  return (
    <div ref={canDrag ? drag : signingObject.ref} style={dragStyles}>
      {React.cloneElement(props.children, { isDragging: isDragging })}
    </div>
  );
};
