import React, { useContext } from "react";
import { SigningContext } from "../signing-context";
import { DragWrapper } from "./drag-field-wrapper.component";
import NewDraggableSigningField from "./new-draggable-field.component";
import { userMatchesSigningLocation } from "../signing-modal.helper";

export const SigningObjects = ({
  signingLocations,
  // page,
  loggedInUserSignature,
  signingLocationsAreDraggable,
  context,
  openSignatureEntryModal,
  isClient,
  clientCollaborators,
  displayShield,
  // modalMode,
  deleteSigningField,
  showSignerDropdown,
  preAuthSigner,
  // onSignatureClick,
  isTemplateMode,
  getFilledSignature,
  shouldShowSelectSignerModal,
  setSigner,
}) => {
  const { signersContext } = useContext(SigningContext);

  return signingLocations
    .filter((signingObject) =>
      isClient || preAuthSigner
        ? (signingObject.value && signingObject.completed_at) ||
          userMatchesSigningLocation(signingObject, context.loggedInUser)
        : true
    )
    .map((signingObject) => {
      const objectId = signingObject.id || signingObject.esigning_location_id;
      const key = `${objectId}${signingObject?.signatory_user_id}`;
      const innerRef = React.createRef();
      signingObject.ref = innerRef;
      const type = signingObject.type;
      const newSigningObject = {
        id: key,
        ...signingObject,
        ...getFilledSignature(loggedInUserSignature, signingObject),
        cpIcon:
          type === "signature"
            ? "communication-signature"
            : type === "initials"
            ? "communication-initials"
            : "misc-calendar",
      };
      return (
        <React.Fragment key={signingObject?.signatory_user_id ? key : objectId}>
          <DragWrapper
            canDrag={signingLocationsAreDraggable}
            signingObject={newSigningObject}
            clientCollaborators={clientCollaborators}
            allUsers={signersContext}
            closeSelectSignerModal={() => shouldShowSelectSignerModal(false, signingObject)}
          >
            <NewDraggableSigningField
              allUsers={signersContext}
              isTemplateMode={isTemplateMode}
              canDrag={true}
              setSigner={setSigner}
              isClient={isClient}
              clientCollaborators={clientCollaborators}
              closeSelectSignerModal={() => shouldShowSelectSignerModal(false, signingObject)}
              context={context}
              deleteSigningField={deleteSigningField}
              displayShield={displayShield}
              onClick={(e) => shouldShowSelectSignerModal(true, signingObject, e)}
              openSelectSignerModal={(e, s = null) => shouldShowSelectSignerModal(true, s || signingObject, e)}
              openSignatureEntryModal={() => openSignatureEntryModal(signingObject, objectId)}
              signingObject={newSigningObject}
              showSignerDropdown={showSignerDropdown}
              signingLocationsAreDraggable={signingLocationsAreDraggable}
            />
          </DragWrapper>
        </React.Fragment>
      );
    });
};
