import React from "react";
import PropTypes from "prop-types";
import KbaFlowManager from "./kba-flow-manager.component";
import KbaAuthBegin from "./start/kba-auth-begin.component";
import KbaAuthSigner from "./signer/kba-auth-signer.component";
import KbaAuthQuestions from "./questions/kba-auth-questions.component";
import KbaVerificationFailed from "./questions/fail-states/kba-verification-failed.component";
import KbaThirdFail from "./questions/fail-states/kba-third-fail.component";
import RequestSent from "./common/request-sent.component";

export default class KbaAuth extends React.Component {
  static propTypes = {
    phoneOnly: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    signer: PropTypes.object,
    verifyKba: PropTypes.func.isRequired,
    clientId: PropTypes.string,
    signerId: PropTypes.string,
    taxPrepManualSign: PropTypes.bool,
    document: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      originalPdfDownloadUrl: PropTypes.string,
      attemptsRemaining: PropTypes.number,
      kbaTimeLimit: PropTypes.number,
      userManuallySigned: PropTypes.bool,
      userElectronicallySigned: PropTypes.bool,
      kbaAttemptsAllowed: PropTypes.number,
    }).isRequired,
  };
  render() {
    const {
      verifyKba,
      closeModal,
      signer,
      document,
      clientId,
      taxPrepManualSign,
      hideEsignRequest,
      phoneOnly,
      signerId,
    } = this.props;
    const {
      id: documentId,
      kbaTimeLimit,
      attemptsRemaining,
      userManuallySigned,
      userElectronicallySigned,
      kbaAttemptsAllowed,
    } = this.props.document;
    const userAlreadySigned = userManuallySigned || userElectronicallySigned;
    return (
      <KbaFlowManager
        taxPrepManualSign={taxPrepManualSign}
        phoneOnly={phoneOnly}
        documentId={documentId}
        verifyKba={verifyKba}
        attemptsRemaining={attemptsRemaining}
        userAlreadySigned={userAlreadySigned}
        signerId={signerId}
      >
        {({
          next,
          getKbaInstance,
          kbaInstance,
          submitResponses,
          onTimerComplete,
          retryAfterFailedAttempt,
          submitManualSignature,
          attemptsRemaining,
        }) => {
          const kbaFlow = [
            <KbaAuthBegin next={next} closeModal={closeModal} />,
            <KbaAuthSigner onSubmit={getKbaInstance} signer={signer} phoneOnly={phoneOnly} />,
            <KbaAuthQuestions
              kbaTimeLimit={kbaTimeLimit}
              instance={kbaInstance}
              submitResponses={submitResponses}
              onTimerComplete={onTimerComplete}
            />,
            <KbaVerificationFailed
              attemptsRemaining={attemptsRemaining}
              kbaAttemptsAllowed={kbaAttemptsAllowed}
              retry={retryAfterFailedAttempt}
              phoneOnly={phoneOnly}
            />,
          ];

          const failureFlow = [
            <KbaThirdFail
              submitManualSignature={submitManualSignature}
              next={next}
              document={document}
              closeSigningExperience={closeModal}
              clientId={clientId}
              hideEsignRequest={hideEsignRequest}
            />,
            <RequestSent closeSigningExperience={closeModal} />,
          ];

          const taxPrepManualSignFlow = [
            <KbaThirdFail
              submitManualSignature={submitManualSignature}
              next={next}
              document={document}
              closeSigningExperience={closeModal}
              clientId={clientId}
              taxPrepManualSign={taxPrepManualSign}
              hideEsignRequest={hideEsignRequest}
            />,
            <RequestSent closeSigningExperience={closeModal} />,
          ];

          if (taxPrepManualSign) {
            return taxPrepManualSignFlow;
          } else if (attemptsRemaining > 0 && !userAlreadySigned) {
            return kbaFlow;
          } else if (userAlreadySigned) {
            return [
              <RequestSent
                closeSigningExperience={closeModal}
                subTitle={`We are waiting for other parties to sign the document.`}
              />,
            ];
          } else {
            return failureFlow;
          }
        }}
      </KbaFlowManager>
    );
  }
}
