import React from "react";
import PropTypes from "prop-types";
import { getDownloadUrl } from "../../signing.resource";
import { handleError } from "src/handle-error.helper";
import Disposable from "react-disposable-decorator";
import { CpButton, CpInput } from "canopy-styleguide!sofe";

import styles from "../esign-modal.styles.css";

@Disposable
export default class HeaderBar extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    documentId: PropTypes.string,
    signingLocationsAreDraggable: PropTypes.bool.isRequired,
    signingRequestIsValid: PropTypes.bool.isRequired,
    completeDocumentAsClient: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    closeCancel: PropTypes.func.isRequired,
    clientRequestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    signingId: PropTypes.string,
    documentCompleted: PropTypes.bool,
    documentType: PropTypes.string,
  };

  state = {
    downloadUrl: `/api/letters/${this.props.documentId}/esign_docs/${
      this.props.signingId
    }/signing_requests/pdf?file_name=${encodeURIComponent(this.props.title)}.pdf`,
    nextLocation: 0,
    scrollContainerClientWidth: null,
    inputChanged: false,
  };

  goToPageContainerRef = React.createRef();

  componentDidMount() {
    if (this.props.documentType === "file" && this.props.documentCompleted) {
      this.props.cancelWhenUnmounted(
        getDownloadUrl(this.props.documentId).subscribe((resp) => {
          this.setState({ downloadUrl: resp.signed_url });
        }, handleError)
      );
    }
  }

  componentDidUpdate() {
    //combining all children with current so we make sure we get the all of the pages in case one is rotated
    // if KBA is on for the doc there is no scrollContainer
    if (!this.props.scrollContainer.current) return;
    const widthsArr = [...this.props?.scrollContainer?.current?.children, this.props?.scrollContainer?.current];
    const maxClientWidth = Math.max(...widthsArr.map((div) => div.clientWidth));
    // if no existing scrollContainerClientWidth then set width to largest client container width
    // checking the scrollContainerClientWidth to the maxClientWidth makes sure we set the max value as pages load
    if (!this.state.scrollContainerClientWidth || this.state.scrollContainerClientWidth !== maxClientWidth) {
      this.setState({ scrollContainerClientWidth: maxClientWidth });
    }
  }

  goToPage = (pageNum) => {
    // if signingLocationsAreDraggable then practitioner view: need to -1 for 0 index count
    // if !signingLocationsAreDraggable then client view: don't -1 because the button is at 0 index
    // if client view & signing fields are all signed then button disappears and need to do the -1 for 0 index count
    let newPageNum = pageNum;
    if (
      this.props.signingLocationsAreDraggable ||
      (this.props.signingRequestIsValid && !this.props.signingLocationsAreDraggable)
    ) {
      newPageNum = pageNum - 1;
    }
    this.props.scrollContainer.current.children[newPageNum]?.scrollIntoView({ block: "start" });
  };

  render() {
    const {
      clientRequestId,
      closeCancel,
      closeModal,
      completeDocumentAsClient,
      currentPage,
      documentCompleted,
      esignSent,
      handleChangePage,
      preAuthSigner,
      scrollContainer,
      signingLocationsAreDraggable,
      signingRequestIsValid,
      signingSVGs,
      title,
    } = this.props;
    const { downloadUrl, inputChanged, scrollContainerClientWidth } = this.state;

    // the scrollContainer.current's offsetWidth includes any padding (which includes the scrollbar if one is shown)
    // so subtracting the clientWidth gives us the width of the scrollbar, otherwise the values are the same and scrollBarWidth = 0
    const scrollBarWidth = scrollContainer?.current?.offsetWidth - scrollContainer?.current?.clientWidth;
    const goToContainerWidth = this.goToPageContainerRef?.current?.clientWidth;
    const marginRight = scrollContainerClientWidth / 2 - goToContainerWidth / 2 + scrollBarWidth || 0;

    // we need to check if KBA active; if not active return true so the go-to-page input shows
    // if KBA active, we need to check if it has been verified and if not then we hide the modal
    const checkKBA = this.props?.esignDocument?.kbaEnabled
      ? this.props.esignDocument.kbaEnabled && this.props.isKbaVerified
      : true;
    const requestTitle = this.props.isTemplateMode
      ? `eSign Template: ${this.props.esignContext.template_name}`
      : this.props.esignContext?.title
      ? this.props.esignContext.title
      : title;
    return (
      <div className={styles.lightToolbar}>
        <div className={styles.toolbarContainer}>
          <div style={{ minWidth: 70 }} className={styles.toolbarElement}>
            <div style={{ width: "100%" }} className={`cp-body cp-ellipsis`}>
              {requestTitle || "Signing document"}
            </div>
          </div>
          {signingSVGs?.length > 1 && checkKBA && (
            <div
              style={{ position: "absolute", right: marginRight }}
              className={styles.goToPageContainer}
              ref={this.goToPageContainerRef}
            >
              <CpInput
                className={"cp-mr-8"}
                onChange={handleChangePage}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    this.goToPage(currentPage);
                    this.setState({ inputChanged: true });
                    e.target.blur();
                  }
                }}
                placeholder={currentPage}
                value={currentPage}
                style={{ width: "48px", fontStyle: inputChanged ? "normal" : "italic" }}
              />
              / {signingSVGs.length}
            </div>
          )}
          {!signingLocationsAreDraggable ? (
            <div className={`${styles.toolbarElement} cp-mr-24`} style={{ justifyContent: "flex-end" }}>
              {clientRequestId && documentCompleted && (
                <div className={styles.toolbarElement} style={{ justifyContent: "center" }}>
                  <div className="cps-btn-icon">
                    <a className="cps-link cp-mr-8" href={`${downloadUrl}`} download>
                      <span className="cps-icon cps-icon-download" />
                      <span className="cps-screenreader">Download {title} to your computer</span>
                    </a>
                  </div>
                </div>
              )}
              {!preAuthSigner && (
                <CpButton btnType="flat" className="cp-mr-16" onClick={closeModal}>
                  Cancel
                </CpButton>
              )}
              <CpButton
                btnType="primary"
                className="cp-mr-16"
                disabled={!signingRequestIsValid || documentCompleted || esignSent}
                onClick={completeDocumentAsClient}
              >
                Send
              </CpButton>
            </div>
          ) : (
            <div className={`${styles.toolbarElement} cp-mr-24`} style={{ justifyContent: "flex-end" }}>
              <div className="cps-btn-icon">
                <a className="cps-link cp-mr-8" onClick={closeCancel}>
                  <span className="cps-icon cps-icon-close" />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
