import React from "react";
import { CprDatepicker } from "canopy-styleguide!sofe";
import styles from "./date-picker.styles.css";
import commonStyles from "../common/common.styles.css";
import { useFeatureBubble } from "feature-toggles!sofe";
import { esignTemplatesFeatureBubble } from "../../constants";

export default function DatePicker({ date, setDueDate }) {
  const handleDateChange = (e) => {
    setDueDate(e.detail ? e.detail.getTime() : null);
  };
  const showEsignTemplates = useFeatureBubble(esignTemplatesFeatureBubble);

  return (
    <CprDatepicker
      id="esign-datepicker"
      className={showEsignTemplates ? "grow" : ""}
      positionAdjustment={showEsignTemplates ? {} : { left: -100 }}
      events={{
        datechange: handleDateChange,
      }}
      orientation="bottom left"
      placeholder={showEsignTemplates ? "- Add due date -" : "Add a due date"}
      inputClass={
        !showEsignTemplates &&
        `${commonStyles.dottedUnderlineLight} ${commonStyles.inputPlaceholderLight} ${
          date ? styles.withDate : styles.withoutDate
        } cps-form-control`
      }
      date={date}
    />
  );
}
