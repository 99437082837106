import React, { useContext } from "react";
import { CpWell, CpNameChip } from "canopy-styleguide!sofe";
import styles from "../../esign-modal.styles.css";
import { SigningContext } from "../../../signing-context";

export const SignerWell = ({ signer, assignedType, actionButton }) => {
  const { isNewCrm } = useContext(SigningContext);
  return (
    <CpWell className="cp-flex-center justify-between cp-p-8 cp-mv-8" level={1}>
      <div className="cp-flex-center min-w-0">
        <CpNameChip
          className={`${
            signer.role === "TeamMember" || signer.user_role === "TeamMember"
              ? styles.teamMemberChip
              : styles.primarySignerChip
          } cp-mr-8`}
          initials={signer?.initials ? signer.initials : undefined}
          icon="person"
          name={signer?.email ? signer?.name : undefined}
        />
        <div className="cp-mr-8 cp-body min-w-0">
          {isNewCrm && <div className="cp-ellipsis cp-caption">{assignedType?.name}</div>}
          <div className="cp-body min-w-0 cp-flex-center justify-between" style={{ width: "218px" }}>
            <div
              className="cp-wt-semibold cp-ellipsis"
              style={assignedType?.name && !isNewCrm ? { flexBasis: "60%" } : {}}
            >
              {`${signer?.isSelf ? "(You) " : ""}${signer.name}` || signer.singer_type_name}
            </div>
            {assignedType?.name && !isNewCrm && <div className="cp-ellipsis cp-caption">({assignedType?.name})</div>}
          </div>
          <div className="cp-ellipsis cp-color-app-secondary-text">{signer.email || "Unassigned"}</div>
        </div>
      </div>
      {actionButton && <div>{actionButton}</div>}
    </CpWell>
  );
};
