import React from "react";
import { partial } from "lodash";

import DocumentView from "../page-layout-components/document-view.component.js";
import { PreviewDocument } from "../page-layout-components/preview-document.component";
import PdfPage from "../document/pdf-page.component";
import HeaderBar from "./page-layout-components/header-bar.component.js";
import { FooterBar } from "./page-layout-components/footer-bar.component.js";
import { NextSignature } from "./page-layout-components/next-signature-buttons.component.js";
import SidePanel from "./page-layout-components/side-panel.component.js";
import SignatureEntryModal from "../signature-entry/signature-entry-modal.component.js";
import ClientRequestSuccessModal from "../client-request/client-request-success-modal.component.js";
import { EsignRequestSuccessNewModal } from "../esign/esign-request-success-new-modal.component";
import { signatureElements, tabs } from "../constants.js";
import { isClient, __openModal, __closeModal } from "../signing-modal.helper.js";
import styles from "./esign-modal.styles.css";
import Disposable from "react-disposable-decorator";
import { CpLoader, CpEmptyState } from "canopy-styleguide!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import KbaAuth from "../kba/kba-auth.component";
import { DragLayer } from "../page-layout-components/drop-wrapper.component.js";
import { NewSidePanel } from "./page-layout-components/new-side-panel.component.js";

@Disposable
export class EsignModal extends React.Component {
  state = {
    activeTab: tabs.SIGNATURE,
    inReview: false,
    currentPage: 1,
    pageRefs: [],
  };

  componentDidMount() {
    __openModal();
  }

  componentWillUnmount() {
    __closeModal();
  }

  phoneOnly = window.matchMedia(
    "screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 599px) and (orientation: landscape)"
  ).matches;

  setActiveTab = (activeTab) => {
    this.setState({ activeTab });
  };

  //eslint-disable-next-line no-dupe-class-members
  setActiveTab = (activeTab) => {
    this.setState({ activeTab });
  };

  beginReview = () => {
    this.setState({ inReview: true });
    this.scrollContainerRef.current.scroll({ top: 0, behavior: "smooth" });
  };

  handleChangePage = (page) => {
    const numbersOnly = page.replace?.(/[^0-9]*/g, "");
    this.setState({ currentPage: numbersOnly || page });
  };

  handleAddPageRef = (ref) => {
    this.setState((prevState) => ({ pageRefs: [...prevState.pageRefs, ref] }));
  };

  scrollContainerRef = React.createRef();

  getPageShownOnScroll = () => {
    const offset = 48;
    const scrollHeight = this.scrollContainerRef.current.getBoundingClientRect().height;

    const pagePositions = this.state.pageRefs.find((pageRef) => {
      if (pageRef?.ref) {
        const { top, bottom } = pageRef?.ref?.current?.getBoundingClientRect();
        const isTopAtTopOfPage = top > offset && top < scrollHeight - scrollHeight / 2;
        const isBottomAtBottomOfPage = bottom < scrollHeight && bottom > scrollHeight / 2;
        return isTopAtTopOfPage || isBottomAtBottomOfPage;
      }
    });

    const visiblePage = pagePositions ? pagePositions.page + 1 : null;
    if (visiblePage && visiblePage !== this.state.currentPage) this.handleChangePage(visiblePage);
  };

  render() {
    const {
      clientId,
      clientPortalInvitesSent,
      clientRequestData,
      clientRequestId,
      closeCancel,
      closeModal,
      completeDocumentAsClient,
      consented,
      context,
      creditsSubtracted,
      documentCompleted,
      documentType,
      resolutionCaseId,
      esignDocument,
      esignSent,
      esignTaskId,
      finalizeEsignExperience,
      finalizeSigningExperience,
      hasVerified,
      kbaEnabled,
      loggedInUserSignature,
      preAuthSigner,
      setClientRequestData,
      setKbaEnabled,
      setLoggedInUserSignature,
      showClientRequestCreatedSuccessfullyModal,
      showEsignRequestCreatedSuccessfullyModal,
      showSignatureEntryModal,
      shouldShowSignatureEntryModal,
      signatureEntryModalType,
      signer,
      signingId,
      signingLocations,
      signingLocationsAreDraggable,
      signingRequestIsValid,
      taskId,
      title,
      updateConsent,
      verifyKba,
      signerId,
      applyTemplate,
      isTemplate,
      signerTypes,
      assignSignerTypes,
      openSignerDropdown,
      showSignerDropdown,
      hasSelf,
      showEsignTemplates,
    } = this.props;

    const { activeTab, currentPage } = this.state;
    const SidePanelComponent = this.props.showEsignTemplates ? NewSidePanel : SidePanel;

    return (
      <div className={`${styles.modalContentLight} cps-modal`} style={{ ...(this.phoneOnly && { minWidth: "auto" }) }}>
        <link
          href="https://fonts.googleapis.com/css?family=Allura|Courgette|Mr+Dafoe|Shadows+Into+Light+Two"
          rel="stylesheet"
        />
        <DragLayer showEsignTemplates={showEsignTemplates} />
        <HeaderBar
          clientRequestId={clientRequestId}
          closeCancel={closeCancel}
          closeModal={closeModal}
          completeDocumentAsClient={completeDocumentAsClient}
          currentPage={currentPage}
          documentCompleted={documentCompleted}
          documentId={esignDocument.id}
          documentType={"file"}
          esignSent={esignSent}
          isClient={isClient(context)}
          phoneOnly={this.phoneOnly}
          preAuthSigner={preAuthSigner}
          scrollContainer={this.scrollContainerRef}
          signingId={signingId}
          signingLocationsAreDraggable={signingLocationsAreDraggable}
          signingRequestIsValid={signingRequestIsValid}
          title={title}
          handleChangePage={this.handleChangePage}
          {...this.props}
        />
        {esignDocument.kbaEnabled && !hasVerified && !esignDocument.completed && (
          <KbaAuth
            closeModal={closeModal}
            signer={signer}
            signerId={signerId}
            kbaTimeLimit={esignDocument.kbaTimeLimit}
            attemptsRemaining={esignDocument.kbaAttemptsRemaining}
            verifyKba={verifyKba}
            clientId={clientId}
            document={esignDocument}
            phoneOnly={this.phoneOnly}
          />
        )}
        {(hasVerified || esignDocument.completed) && (
          <div className={`${styles.modalColumns}`}>
            {signingLocationsAreDraggable && (
              <SidePanelComponent
                activeTab={activeTab}
                clientId={clientId}
                clientRequestData={clientRequestData}
                context={context}
                creditsSubtracted={creditsSubtracted}
                documentType={documentType}
                resolutionCaseId={resolutionCaseId}
                finalizeEsignExperience={finalizeEsignExperience}
                finalizeSigningExperience={finalizeSigningExperience}
                kbaEnabled={kbaEnabled}
                requireClientRequest={false}
                setActiveTab={this.setActiveTab}
                setClientRequestData={setClientRequestData}
                setKbaEnabled={setKbaEnabled}
                showClientRequestCreatedSuccessfullyModal={showClientRequestCreatedSuccessfullyModal}
                signatureElements={signatureElements}
                signingId={signingId}
                signingLocations={signingLocations}
                signingRequestIsValid={signingRequestIsValid}
                tabs={tabs}
                title={title}
                applyTemplate={applyTemplate}
                isTemplate={isTemplate}
                signerTypes={signerTypes}
                assignSignerTypes={assignSignerTypes}
                openSignerDropdown={openSignerDropdown}
                showSignerDropdown={showSignerDropdown}
                shouldShowSignatureEntryModal={shouldShowSignatureEntryModal}
                hasSelf={hasSelf}
                loggedInUserSignature={loggedInUserSignature}
                isTemplateMode={this.props.isTemplateMode}
                primaryClientClientPortalUsers={this.props.primaryClientClientPortalUsers}
                documentSizes={this.props.documentSizes}
                closeModal={closeModal}
                showEsignTemplates={showEsignTemplates}
                esignTemplateAccess={this.props.esignTemplateAccess}
                setSigningLocations={this.props.setSigningLocations}
                addSigningLocations={this.props.addSigningLocations}
              />
            )}
            <div
              id="docScroll"
              ref={this.scrollContainerRef}
              onScroll={(e) => {
                this.props.getScrollPosition(e);
                this.getPageShownOnScroll();
              }}
              className={`${styles.documentPane}`}
              style={{ ...(this.phoneOnly && { minWidth: "auto", paddingBottom: 0, marginTop: 36 }) }}
            >
              {this.props.signingSVGs && !signingLocationsAreDraggable && (
                <NextSignature
                  documentSizes={this.props.documentSizes}
                  goToPrevSigningLocations={this.props.goToPrevSigningLocations}
                  goToSigningLocations={this.props.goToSigningLocations}
                  inReview={this.state.inReview}
                  nextLocation={this.props.nextLocation}
                  prevLocation={this.props.prevLocation}
                  scrollContainer={this.scrollContainerRef}
                  scrollEnded={this.props.scrollEnded}
                  signingRequestIsValid={this.props.signingRequestIsValid}
                />
              )}
              {this.renderDocumentContents()}
            </div>
          </div>
        )}
        {showSignatureEntryModal && (
          <SignatureEntryModal
            signAndSend={this.props.signAndSend}
            shouldShowSignatureEntryModal={shouldShowSignatureEntryModal}
            setSignature={setLoggedInUserSignature}
            context={context}
            signatureData={loggedInUserSignature}
            signingType={signatureEntryModalType}
            hasConsented={consented}
            updateConsent={updateConsent}
            documentId={esignDocument.id}
            phoneOnly={this.phoneOnly}
          />
        )}
        {showClientRequestCreatedSuccessfullyModal && (
          <ClientRequestSuccessModal clientId={clientId} resolutionCaseId={resolutionCaseId} closeModal={closeModal} />
        )}
        {
          //new modal
          showEsignRequestCreatedSuccessfullyModal && !showEsignTemplates && (
            <EsignRequestSuccessNewModal
              clientId={clientId}
              esignTaskId={esignTaskId}
              showEsignRequestCreatedSuccessfullyModal={showEsignRequestCreatedSuccessfullyModal}
              closeModal={closeModal}
              taskId={taskId}
              clientPortalInvitesSent={clientPortalInvitesSent}
            />
          )
        }
        {!signingLocationsAreDraggable && !documentCompleted && (
          <FooterBar
            signingRequestIsValid={signingRequestIsValid}
            completeDocumentAsClient={completeDocumentAsClient}
            esignSent={esignSent}
            reviewDocument={this.beginReview}
            documentCompleted={documentCompleted}
          />
        )}
      </div>
    );
  }

  renderDocumentContents = () => {
    if (this.props.esignError) {
      return this.renderError();
    }

    if (this.props.signingSVGs) {
      return this.props.signingSVGs.map((signingSVG, page) => (
        <DocumentView
          showEsignTemplates={this.props.showEsignTemplates}
          clientCollaborators={this.props.clientCollaborators}
          clientId={this.props.clientId}
          context={this.props.context}
          displayShield={this.props.kbaEnabled}
          documentSizes={this.props.documentSizes}
          isClient={isClient(this.props.context)}
          key={page}
          loggedInUserSignature={this.props.loggedInUserSignature}
          modalMode={true}
          onRemoveSignature={this.props.onRemoveSignature}
          onSelectSigner={this.props.onSelectSigner}
          openClientModal={this.props.openClientModal}
          page={page}
          phoneOnly={this.phoneOnly}
          preAuthSigner={this.props.preAuthSigner}
          primaryClientClientPortalUsers={this.props.primaryClientClientPortalUsers}
          resetAddedUsers={this.props.resetAddedUsers}
          selectSignerNewUser={this.props.selectSignerNewUser}
          setDocumentSize={this.props.setDocumentSizes}
          setSigningLocations={this.props.setSigningLocations}
          shouldShowSignatureEntryModal={this.props.shouldShowSignatureEntryModal}
          signingLocations={this.props.signingLocations}
          signingLocationsAreDraggable={this.props.signingLocationsAreDraggable}
          signingSVG={signingSVG}
          determineDocumentLoaded={this.props.determineDocumentLoaded}
          handleAddPageRef={this.handleAddPageRef}
          assignSignerTypes={this.props.assignSignerTypes}
          isTemplate={this.props.isTemplate}
          showSignerDropdown={this.props.showSignerDropdown}
          resetSignerDropdown={this.props.resetSignerDropdown}
          isTemplateMode={this.props.isTemplateMode}
        />
      ));
    }

    if (this.props.signedSVGs) {
      return this.props.signedSVGs.map((url, page) =>
        featureEnabled("esign_pdf") ? (
          <PdfPage key={`preview-${page}`} src={url} onLoad={partial(this.props.setDocumentSizes, page)} />
        ) : (
          <PreviewDocument key={page} svg={url} setDocumentSize={this.props.setDocumentSizes} />
        )
      );
    }

    return this.renderLoading();
  };

  renderLoading = () => {
    return (
      <div className={`cps-card cps-depth-2 ${styles.loadingWrapper} ${styles.document}`}>
        <div className={styles.loader}>
          <CpLoader size="lg" />
          <p className="cps-body cps-cool-gray cps-text-center">
            <span className="cps-wt-bold">We are preparing your eSign document.</span>
            <br />
            <span>Please wait or check back later.</span>
          </p>
        </div>
      </div>
    );
  };

  renderError = () => {
    return (
      <div className={`cps-card cps-depth-2 ${styles.loadingWrapper} ${styles.document}`}>
        <div className={styles.loader}>
          <CpEmptyState
            img="es_caution"
            text="There was a problem preparing this file."
            subText="Please try again later."
          />
        </div>
      </div>
    );
  };
}
