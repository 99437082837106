exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".kba-flow-manager-styles__kbaCardCentered--1u4Cc {\n  align-items: center;\n  justify-content: center;\n  flex-grow: 1;\n  display: flex;\n  max-width: 500px;\n}\n.kba-flow-manager-styles__alignIcon--1YlbY {\n  display: flex;\n  align-items: center;\n}\n.kba-flow-manager-styles__kbaFlowIconBorder--jnFnX {\n  border: 2px solid #fff;\n  border-radius: 100%;\n}\n.kba-flow-manager-styles__kbaCard--1UeRS {\n  min-height: 550px;\n  min-width: 500px;\n  display: flex;\n  flex-direction: column;\n}\n\n.kba-flow-manager-styles__loaderContainer--PCMwf {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n}\n\n@media screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 599px) and (orientation: landscape) {\n  .kba-flow-manager-styles__kbaCardCentered--1u4Cc {\n    display: block;\n    max-width: none;\n    overflow-y: auto;\n    width: 100%;\n  }\n\n  .kba-flow-manager-styles__kbaCard--1UeRS {\n    min-width: initial;\n    min-height: 100%;\n  }\n}", ""]);
// Exports
exports.locals = {
	"kbaCardCentered": "kba-flow-manager-styles__kbaCardCentered--1u4Cc",
	"alignIcon": "kba-flow-manager-styles__alignIcon--1YlbY",
	"kbaFlowIconBorder": "kba-flow-manager-styles__kbaFlowIconBorder--jnFnX",
	"kbaCard": "kba-flow-manager-styles__kbaCard--1UeRS",
	"loaderContainer": "kba-flow-manager-styles__loaderContainer--PCMwf"
};