import React from "react";
import PropTypes from "prop-types";
import styles from "./kba-auth-begin.styles.css";
export default class KbaAuthBegin extends React.Component {
  static propTypes = {
    next: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };
  render() {
    return (
      <div className="cps-text-center" style={{ padding: "0 2rem 2rem" }}>
        <div className={`cps-margin-top-24 ${styles.kbaShieldImage}`}></div>
        <h3 className="cps-header-sm cps-wt-bold" style={{ margin: "18px 0 24px" }}>
          Signer Authentication
        </h3>
        <p className="cps-text-center cps-body">
          Answer <strong className="cps-wt-bold">four</strong> multiple choice questions to verify your identity and
          authenticate your eSignature.
        </p>
        <div
          className="cps-well cps-padding-8 cps-body"
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <span className="cps-icon cps-icon-recent cps-margin-right-4" aria-hidden="true"></span>
          <span>
            The <strong className="cps-wt-bold">timed</strong> authentication process will take{" "}
            <strong className="cps-wt-bold cps-color-blue">5 minutes</strong>
          </span>
        </div>
        <div className="cps-text-center cps-margin-top-24">
          <button className="cps-btn +primary cps-margin-right-8" type="button" onClick={this.props.next}>
            I'm ready
          </button>
          <button
            className="cps-btn +secondary cps-margin-left-8"
            type="button"
            onClick={() => this.props.closeModal("retry")}
          >
            I'll come back later
          </button>
        </div>
      </div>
    );
  }
}
