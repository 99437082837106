import pdfjs from "pdfjs-dist";
import React from "react";
import ResizeSensor from "./resize.helper.js";
import "./pdf-page.styles.css";

pdfjs.GlobalWorkerOptions.workerSrc = "https://cdn.canopytax.com/sofe/pdfjs/pdf.worker-2.0.489.min.js";

const SCALE = 1.5; // Adjust this to change the resolution (quality) of the output canvas.

export default class PdfPage extends React.Component {
  constructor() {
    super();
    this.scaleAnnotations = this._scaleAnnotations.bind(this);

    // The resize sensor is used for detecting when the pdf page has been resized due to
    // scaling of the outer containers. Too bad JS doesn't provide a native way to do this.
    this.resizeSensor = ResizeSensor();
  }

  componentDidMount() {
    this.mounted = true;

    let { src, onLoad } = this.props;

    pdfjs
      .getDocument(src)
      .then((pdf) => {
        return pdf
          .getPage(1) // The pdf should only have one page
          .then((pdfPage) => {
            const viewport = pdfPage.getViewport(SCALE);
            const canvas = this.canvas;

            if (!this.mounted) return; // Check if the preview was closed before we get to here

            canvas.width = viewport.width;
            canvas.height = viewport.height;

            const ctx = canvas.getContext("2d");

            const renderTask = pdfPage.render({
              canvasContext: ctx,
              viewport: viewport,
            });

            const annotationsTask = pdfPage.getAnnotations({ intent: "display" }).then((annotations = []) => {
              // Filter out all link annotations. There may be other types
              // of annotations to also get rid of.
              annotations = annotations.filter((annotation) => annotation.subtype !== "Link");
              if (!annotations.length || !this.mounted) return;

              const annotationViewport = viewport.clone({ dontFlip: true });
              const parameters = {
                viewport: annotationViewport,
                annotations: annotations,
                page: pdfPage,
                div: this.annotationLayer,
              };

              // Store the width of the viewport to use on the annotation wrapper
              this.width = viewport.width;
              this.height = viewport.height;

              return pdfjs.AnnotationLayer.render(parameters);
            });

            return Promise.all([renderTask.promise, annotationsTask]);
          });
      })
      .then(() => {
        if (this.mounted) {
          onLoad({ width: this.canvas.width, height: this.canvas.height });
        }
      }) // If we aren't mounted, don't execute the parent load callback
      .then(() => {
        setTimeout(() => {
          this.setupEvents.apply(this);
        });
      })
      .catch(this.props.onError);
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.resizeSensor && this.wrapper) this.resizeSensor.destroy(this.wrapper);
  }

  setupEvents() {
    if (this.wrapper) this.resizeSensor.create(this.wrapper, this.scaleAnnotations);
    setTimeout(this.scaleAnnotations, 100);
  }

  _scaleAnnotations() {
    // Scale the annotation layer to match the canvas layer
    if (this.canvas && this.canvas.clientWidth) {
      const scale = this.canvas.clientWidth / this.canvas.width;
      let shift = ((1 - scale) * 100) / 2;
      shift = shift * -1;

      this.annotationLayer.style.width = this.canvas.width + "px";
      this.annotationLayer.style.height = this.canvas.height + "px";
      this.annotationLayer.style.transform = `translate(${shift}%, ${shift}%) scale(${scale})`;
    } else if (this.mounted) {
      setTimeout(this.scaleAnnotations);
    }
  }

  scrollIntoView() {
    this.wrapper.scrollIntoView();
  }

  render() {
    const { style, className = "" } = this.props;
    return (
      <div
        className={`docs-ui-preview-page`}
        style={{
          position: "relative",
        }}
        ref={(el) => {
          this.wrapper = el;
        }}
      >
        <div className={`${className}`} style={{ width: this.width, ...style, position: "relative" }}>
          <div
            style={{ top: 0, left: 0, position: "absolute" }}
            ref={(el) => {
              this.annotationLayer = el;
            }}
            className="annotationLayer"
          ></div>
        </div>
        <canvas
          className={`${className}`}
          style={{ ...style }}
          ref={(el) => {
            this.canvas = el;
          }}
        ></canvas>
      </div>
    );
  }

  get offsetTop() {
    return this.wrapper.offsetTop;
  }

  get clientHeight() {
    return this.wrapper.clientHeight;
  }
}
