import React from "react";

export default class LoadingOnSubmit extends React.Component {
  render() {
    return (
      <div
        className="cps-margin-top-32"
        style={{
          textAlign: "center",
          display: "flex",
          flex: "1 0 100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <img src="https://cdn.canopytax.com/static/signing-ui/faded_loading_shield.svg" />
        <h1 className="cps-wt-bold cps-subheader cps-color-gray1">Calculating Your Results</h1>
      </div>
    );
  }
}
