import React from "react";

import styles from "./title.styles.css";

export default function Title({ setTitle, title }) {
  return (
    <div>
      <input value={title} onChange={setTitle} className={styles.titleLight} placeholder="Untitled"></input>
    </div>
  );
}
