import React, { useState, useEffect, useContext } from "react";
import { useCss, k } from "kremling";
import { keys, findIndex, remove, cloneDeep, includes } from "lodash";
import { CpButton, CpCard, CpInput } from "canopy-styleguide!sofe";
import { postUser, patchSigner, deleteSigner } from "../signing.resource.js";
import { SelectSignerList } from "./select-signer-users-list.component";
import { SelectSignerAddEditUser } from "./select-signer-add-edit-user.component.js";
import { SigningContext } from "../signing-context";
import { clientTypes, userRoles } from "../constants.js";
import { forkJoin } from "rxjs";
import { handleError } from "src/handle-error.helper.js";

export default function SelectSignerNewModal({
  clientCollaborators,
  clientId,
  context,
  primaryClientClientPortalUsers,
  resetSigner,
  setSigner,
  signingField,
  signingLocations,
}) {
  const scope = useCss(css);
  const { signersContext, setSignersContext } = useContext(SigningContext);

  const [clients] = useState(signersContext);
  const [filteredClients, setFilteredClients] = useState(signersContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddEditUser, setShowAddEditUser] = useState(false);
  const [user, setUser] = useState(""); //this is the user to be edited
  const [updatedUser, setUpdatedUser] = useState(null);

  useEffect(() => {
    if (!updatedUser) return;
    const isEmailChanged = user?.email ? user.email !== updatedUser.email : true;
    if (updatedUser?.edited && !isEmailChanged) {
      // to edit name only
      const sub = patchSigner(updatedUser).subscribe((editedUser) => {
        setUserData(true, editedUser);
      }, handleError);

      return () => sub?.unsubscribe();
    }

    if (user?.email && isEmailChanged) {
      const sub = forkJoin({
        deleteSigner: deleteSigner(updatedUser.id, clientId),
        newUser: postUser(updatedUser),
      }).subscribe(({ newUser }) => {
        signingLocations
          .filter((signingLocation) => signingLocation.signer_id === user.id)
          .forEach((signingField) => {
            setSigner(newUser?.user_id, newUser?.name, signingField.id, newUser?.id, newUser?.role);
          });
        setUserData(true, newUser);
      }, handleError);

      return () => sub?.unsubscribe();
    }

    const sub = postUser(updatedUser).subscribe((newUser) => setUserData(false, newUser), handleError);

    return () => sub?.unsubscribe();
  }, [updatedUser]);

  useEffect(() => {
    setFilteredClients(signersContext);
  }, [signersContext]);

  useEffect(() => {
    //if the signing field changes clear add/edit on unmount
    return () => setShowAddEditUser();
  }, [signingField]);

  const setUserData = (edit, newUser = null) => {
    //if we are running a POST we get a new user back and add some props
    if (newUser) {
      newUser.role = userRoles.client;
      newUser.canEdit = true;
      newUser.sendClientPortalInvite = updatedUser.sendClientPortalInvite;
      newUser.relationship = "Custom Signer";
      newUser.clientType = "Other Clients"; // custom signers should always be "Other Clients"
      newUser.emailType = "Email";
      setSigner(newUser?.user_id, newUser?.name, signingField.id, newUser?.id, newUser?.role);
    }
    //if we are patching or deleting we need to splice the list, and set the updatedUser to update existing signing fields
    if (edit) {
      const updatedUsersList = cloneDeep(signersContext);
      const userIndex = findIndex(updatedUsersList, (client) => client.id === updatedUser.id);
      updatedUsersList.splice(userIndex, 1, newUser ? newUser : updatedUser);
      setSignersContext(updatedUsersList);
    }
    //if we are POSTING only we should just add the new user to the top of the list
    if (!edit && newUser) {
      const combinedSignerList = [newUser, ...signersContext];
      // since cp and non-cp signers combined in signersContext, compare to clientCollaborators and re-alphebatize the non-cp list
      const clientPortalSignerList = combinedSignerList.filter((client) => {
        if (includes(clientCollaborators, client.name)) {
          return client;
        }
      });
      const alphabetizedNonClientPortalSignerList = combinedSignerList
        .filter((client) => {
          if (!includes(clientCollaborators, client.name)) {
            return client;
          }
        })
        .sort((a, b) => a.name.localeCompare(b.name));
      setSignersContext([...clientPortalSignerList, ...alphabetizedNonClientPortalSignerList]);
    }
    setUpdatedUser();
    setUser();
    setShowAddEditUser(false);
  };

  const searchPeople = (value) => {
    const searchValue = value.toLowerCase();
    setSearchTerm(value);
    const searchResult =
      value.length > 0
        ? clients.filter(
            (user) => user.name.toLowerCase().includes(searchValue) || user.email.toLowerCase().includes(searchValue)
          )
        : clients;
    setFilteredClients(searchResult);
  };

  const addOrEditUser = (editedUser) => {
    if (!editedUser.client_id) editedUser.client_id = Number(clientId);
    setUpdatedUser(editedUser);
  };

  const deleteUser = (userId) => {
    deleteSigner(userId, clientId).toPromise();
    const updatedUsersList = cloneDeep(signersContext);
    remove(updatedUsersList, (client) => client.id === userId);
    setSignersContext(updatedUsersList);
    resetSigner(signingField.id);
    setShowAddEditUser(false);
    setUser();
    setUpdatedUser();
  };

  return (
    <div {...scope}>
      {!showAddEditUser && (
        <CpCard className="signer-container">
          <CpCard.Header className="cps-title">
            <CpInput isSearch placeholder="Search People" onChange={searchPeople} className="input-search" />
          </CpCard.Header>
          <CpCard.Body className="signer-list">
            {keys(clientTypes).map((type) => (
              <SelectSignerList
                allUsers={filteredClients}
                context={context}
                key={type}
                searchTerm={searchTerm}
                setShowAddEditUser={setShowAddEditUser}
                setSigner={setSigner}
                setUser={setUser}
                signingField={signingField}
                signingLocations={signingLocations}
                type={clientTypes[type]}
                users={filteredClients}
              />
            ))}
          </CpCard.Body>
          <CpCard.Footer>
            <CpButton
              btnType="primary"
              icon="add-large"
              aria-label="Add new signer"
              onClick={() => setShowAddEditUser(true)}
            >
              Add new signer
            </CpButton>
          </CpCard.Footer>
        </CpCard>
      )}
      {showAddEditUser && (
        <SelectSignerAddEditUser
          allUsers={filteredClients}
          addOrEditUser={addOrEditUser}
          deleteUser={deleteUser}
          primaryClientClientPortalUsers={primaryClientClientPortalUsers}
          setShowAddEditUser={setShowAddEditUser}
          setUser={setUser}
          user={user}
        />
      )}
    </div>
  );
}

const css = k`
  .cp-input.input-search {
    width: 392px;
  }
  
  .signer-container {
    display: flex;
    flex-direction: column;
    min-height: 355px;
    max-height: 446px;
  }
  
  .signer-list {
    flex: 1;
    overflow-y: auto;
    padding: 0rem 2rem 2rem !important;
  }
`;
