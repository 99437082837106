import { fetchAsObservable } from "fetcher!sofe";

export function createClientRequest(clientId, resolutionCaseId, clientRequest) {
  return fetchAsObservable(`/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/requests/`, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
    body: JSON.stringify(clientRequest),
  });
}

export function getClientRequest(clientId, resolutionCaseId, clientRequestId) {
  return fetchAsObservable(`/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/requests/${clientRequestId}`);
}

export function updateClientRequest(clientId, resolutionCaseId, clientRequestId, clientRequestBody) {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/requests/${clientRequestId}`,
    { method: "PUT", body: JSON.stringify(clientRequestBody) }
  );
}

export function sendClientRequest(clientId, clientRequestId) {
  const body = {
    client_requests: [
      {
        id: clientRequestId,
        sent: true,
        client_url: getUrlFromState("client.general.requests.instance", {
          clientRequestId: clientRequestId,
          clientId: clientId,
          signing: true,
        }),
      },
    ],
    client_url_to_requests: getUrlFromState("client.general.requests", { clientId: clientId }),
  };

  return fetchAsObservable(`/api/clients/${clientId}/requests`, {
    method: "PUT",
    body: JSON.stringify(body),
  });
}

function getUrlFromState(state, params) {
  return `${window.location.origin}/#/redirect?state=${encodeURI(state)}&params=${encodeURI(
    JSON.stringify(params || {})
  )}`;
}
