import React from "react";

import styles from "./due-date.styles.css";
import DatePicker from "./date-picker.component.js";
import { CpButton } from "canopy-styleguide!sofe";

export default function DueDate({ date, setDueDate, removeDueDate }) {
  return (
    <div className={styles.dueDateContainerLight}>
      <div className={styles.dueDateElementLight}>Due date:</div>
      <div className={styles.dueDateElementLight}>
        <DatePicker date={date} setDueDate={setDueDate} />
      </div>
      <div className={styles.dueDateElementLight} style={{ marginLeft: "auto" }}>
        {date && (
          <CpButton
            onClick={removeDueDate}
            className="cps-cursor-pointer"
            icon="crud-trash-small"
            aria-label="delete"
          />
        )}
      </div>
    </div>
  );
}
