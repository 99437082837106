exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".kba-third-fail-styles__downloadBanner--V7azA {\n  width: 100%;\n  padding: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.kba-third-fail-styles__uploadContainer--2JrOK {\n  min-height: 100px;\n}\n\n.kba-third-fail-styles__uploadWell--1Gw24 {\n  min-height: 100px;\n  width: 100%;\n  border: dashed 2px var(--cps-color-athens);\n  border-radius: .5rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.kba-third-fail-styles__fileCard--12RXE {\n  width: 100%;\n  min-height: 40px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border: solid 1px var(--cps-color-athens);\n  padding: 0 8px;\n}\n\n.kba-third-fail-styles__fileActionSet--11e_e {\n  display: flex;\n}\n\n.kba-third-fail-styles__fileActionSet--11e_e a,\n.kba-third-fail-styles__fileActionSet--11e_e button {\n  color: var(--cps-color-monsoon);\n  background-color: transparent;\n  border: none;\n  padding-left: 8px;\n  padding-right: 0;\n}\n\n.kba-third-fail-styles__submitButtons--2uwZU {\n  display: flex;\n}\n\n@media screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 599px) and (orientation: landscape) {\n  .kba-third-fail-styles__submitButtons--2uwZU {\n    flex-direction: column;\n  }\n\n  .kba-third-fail-styles__submitButtons--2uwZU button {\n    width: 100%;\n    margin-left: 0 !important;\n    margin-bottom: 8px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"downloadBanner": "kba-third-fail-styles__downloadBanner--V7azA",
	"uploadContainer": "kba-third-fail-styles__uploadContainer--2JrOK",
	"uploadWell": "kba-third-fail-styles__uploadWell--1Gw24",
	"fileCard": "kba-third-fail-styles__fileCard--12RXE",
	"fileActionSet": "kba-third-fail-styles__fileActionSet--11e_e",
	"submitButtons": "kba-third-fail-styles__submitButtons--2uwZU"
};