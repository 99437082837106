import React from "react";
import { CpButton, CpModal } from "canopy-styleguide!sofe";

export const SignerConfirmDeleteModal = ({ onSubmit }: { onSubmit: (submit: string) => void }) => {
  return (
    <>
      <CpModal.Header title="Delete Custom Signer?" />
      <CpModal.Body style={{ height: "160px" }}>
        If deleted, this custom signer will no longer be available for future eSign requests. To remove the signer from
        this eSign request only and save it for future use, select “remove from request".
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton onClick={() => onSubmit("delete")}>Delete custom signer</CpButton>
        <CpButton btnType="flat" onClick={() => onSubmit("remove")}>
          Remove from request
        </CpButton>
      </CpModal.Footer>
    </>
  );
};
