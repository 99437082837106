import React from "react";
import styles from "../signing-modal.styles.css";
import { CpButton, CpEmptyState, CpModal } from "canopy-styleguide!sofe";

export const EsignRequestSuccessNewModal = ({
  clientId,
  closeModal,
  esignTaskId,
  clientPortalInvitesSent,
  showEsignRequestCreatedSuccessfullyModal,
  taskId,
}) => {
  let taskUrl = `/#/tasks/clients/${clientId}?esignTaskCreated=${esignTaskId}`;

  // if there is a parent task then route to created subtask
  if (taskId) {
    taskUrl = `/#/task/${taskId}/client/${clientId}?subtask=${esignTaskId}`;
  }

  return (
    <div>
      <CpModal show={showEsignRequestCreatedSuccessfullyModal} onClose={() => closeModal("sent")} width={520}>
        <CpButton
          className={`${styles.closeButton}`}
          icon="close-large"
          aria-label="close"
          onClick={() => closeModal("sent")}
        />
        <CpModal.Body className={`${styles.modalBody}`}>
          {clientPortalInvitesSent && <div className="cp-subheader cp-text-center cp-mb-16">Successfully Sent</div>}
          <CpEmptyState
            img="es_paper-plane"
            text={
              clientPortalInvitesSent
                ? "eSign Requests and Client Portal Invites were sent."
                : "Your client request has been sent."
            }
            subText={
              !clientPortalInvitesSent
                ? "We've created a task to allow you to manage and view status for this request. Client portal invites can be managed on the client page."
                : "We've created a task to allow you to manage and view status for this request."
            }
            cta={
              <>
                <CpButton
                  btnType="primary"
                  anchor
                  className="cp-mt-8"
                  href={taskUrl}
                  onClick={() => closeModal("sent")}
                >
                  View created task
                </CpButton>
                <CpButton btnType="flat" className="cp-ml-8 cp-mt-8" onClick={() => closeModal("sent")}>
                  Close
                </CpButton>
              </>
            }
          />
        </CpModal.Body>
      </CpModal>
    </div>
  );
};
