import React from "react";
import PropTypes from "prop-types";
import styles from "./common-signing-field.styles.css";
import { signingFieldTypes } from "../constants.js";
import { convertDateToString, userMatchesSigningLocation } from "../signing-modal.helper.js";

export default class NonDraggableSigningField extends React.Component {
  static propTypes = {
    signingObject: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    isClient: PropTypes.bool,
    clientCollaborators: PropTypes.object.isRequired,
    modalMode: PropTypes.bool,
    onClickOfSignature: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { allUsers, signingObject, context, clientCollaborators, modalMode } = this.props;
    const signDate = signingObject.completed_at;
    const forCurrentUser = userMatchesSigningLocation(signingObject, context.loggedInUser);
    const client = allUsers.find((user) => userMatchesSigningLocation(signingObject, user));
    //TODO: do we need the clientCollaborators[signingObject.signatory_user_id] check?
    const clientName = client?.name || clientCollaborators[signingObject.signatory_user_id];
    const isSignatureObject = signingObject.type === signingFieldTypes.SIGNATURE;
    const isInitialObject = signingObject.type === signingFieldTypes.INITIAL;
    const isDateObject = signingObject.type === signingFieldTypes.DATE;
    const hasBeenSigned = signingObject.hasBeenSigned || signingObject.signed;
    const signStyle = modalMode ? styles.modalMode : styles.inlineMode;
    const location = {
      marginTop: signingObject.y,
      marginLeft: signingObject.x,
    };

    return signingObject.value && signDate && (!forCurrentUser || isDateObject || hasBeenSigned) ? (
      <div
        className={`${styles.filledSigningObject} ${signStyle}`}
        ref={this.props.innerRef}
        onClick={!isDateObject ? (forCurrentUser && !hasBeenSigned ? this.props.onClickOfSignature : null) : null}
        style={{
          ...location,
          fontFamily: isDateObject ? "Source Sans Pro" : signingObject.font,
          cursor: !isDateObject && forCurrentUser && !hasBeenSigned ? "pointer" : "default",
          fontSize: isDateObject ? "16pt" : "20pt",
        }}
      >
        {/* Display filled signature */}
        {isDateObject ? convertDateToString(signDate) : signingObject.value}
      </div>
    ) : forCurrentUser ? (
      <div
        className={`${styles.unfilledSigningObjectGreen} ${signStyle}`}
        ref={this.props.innerRef}
        onClick={!isDateObject ? this.props.onClickOfSignature : null}
        style={{
          ...location,
          cursor: isDateObject ? "default" : "pointer",
        }}
      >
        {/* Empty Signable Signature Element */}
        {!isSignatureObject ? (isInitialObject ? "Initial" : "Date") : "My Signature"}
      </div>
    ) : (
      !this.props.isClient &&
      !this.props.preAuthSigner && (
        <div
          className={`${styles.unfilledSigningObjectBlue} ${signStyle}`}
          ref={this.props.innerRef}
          style={{
            ...location,
            cursor: "not-allowed",
          }}
        >
          {/* Empty Non-signable Signature Element (Only shown to practitioner, not client) */}
          {isSignatureObject
            ? clientName || signingObject?.signatory_user_name || "Recipient Signature"
            : isInitialObject
            ? "Initial"
            : "Date"}{" "}
          {!isSignatureObject && clientName ? `: ${clientName}` : null}
        </div>
      )
    );
  }
}
