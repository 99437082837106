import React, { useContext, useMemo, useState } from "react";
import { CpButton, CpSelectMulti, CpSelectSingle } from "canopy-styleguide!sofe";
import { templateClientTypes, templateSignerTypes, isNewCrmContactType, isOldCrmClientType } from "../../../constants";
import { SigningContext } from "../../../signing-context";
import { groupDataForSelect, transformUsersForSelect } from "../../../signing-modal.helper";
import { uniq } from "lodash";

export const SelectSignerDropdown = ({
  isTemplateMode,
  signerType = {},
  assignSignerTypes,
  onClose,
  isMulti = false,
  renderTrigger,
}) => {
  const { isNewCrm, signersContext, signerTypesContext, selectedSignerIds, setSelectedSignerIds, getSelectedSigners } =
    useContext(SigningContext);
  const [searchValue, setSearchValue] = useState("");

  const groupUsers = useMemo(() => {
    const signersArray = isTemplateMode ? signerTypesContext : signersContext;
    const groupKeys = isTemplateMode
      ? templateSignerTypes
      : { ...(isNewCrm ? isNewCrmContactType : isOldCrmClientType), ...templateClientTypes };

    const groups = groupDataForSelect(groupKeys);
    return groups.map((group) => {
      return {
        ...group,
        data: signersArray
          .sort((a, b) => a?.name?.localeCompare(b?.name))
          //TODO should we sort custom signers to the bottom?
          // .sort((a, b) => (!a?.canEdit && b.canEdit ? -1 : a.canEdit && !b.canEdit ? 1 : 0))
          .filter((user) => {
            if (isTemplateMode) {
              if (group.name === templateSignerTypes.custom.name) return !user.preset;
              if (group.name === templateSignerTypes.preset.name) return user.preset;
            }

            const isMatch = user?.clientType === group.name || user?.role === group.key;
            if (!isMatch) return false;
            // do individual checks so no duplicates in other/client
            if (group.key === "Other" && user.clientType === "Other Clients") return true;
            if (group.key === "Client" && user.role === "Client" && user.clientType !== "Other Clients") return true;
            return group.key === "TeamMember" || group.key === "Current";
          }),
      };
    });
  }, [signersContext, signerTypesContext]);

  const addSigner = (signers) => {
    setSelectedSignerIds(signers.map((signer) => signer.id));
    const roles = signerTypesContext.filter(
      (type) => type.assignedTo && !signers.find((signer) => signer.id === type.assignedTo.id)
    );
    roles?.forEach((role) => assignSignerTypes?.({ signatory_user_id: null, signer_id: null }, role));
  };

  const addSingleSigner = (signer) => {
    setSelectedSignerIds(uniq([...selectedSignerIds, signer.id]));
  };

  const applySignerSelection = (selectedSigner) => {
    assignSignerTypes(selectedSigner, signerType);
    addSingleSigner(selectedSigner);
  };

  const SelectComponent = isMulti ? CpSelectMulti : CpSelectSingle;

  const onChange = isMulti ? addSigner : (selected) => applySignerSelection(selected);
  const value = isMulti ? getSelectedSigners() : signerType?.signer_type;
  const position = isMulti ? "bottom-start" : "bottom-end";
  const width = isMulti ? 308 : 292;
  const filter = isMulti ? CpSelectMulti.filterAlpha(searchValue) : CpSelectSingle.filterAlpha(searchValue);
  //TODO: disable users already assigned to other signerTypes
  return (
    <>
      <SelectComponent
        isGroupData
        insertSearch
        position={position}
        contentWidth={width}
        data={groupUsers}
        searchValue={searchValue}
        searchOnChange={setSearchValue}
        searchFilter={filter}
        transformData={(user) => transformUsersForSelect(user, signerTypesContext, isNewCrm)}
        onChange={onChange}
        value={value}
        renderTrigger={renderTrigger}
        renderFooter={
          isMulti
            ? ({ close }) => (
                <>
                  <CpButton btnType="primary" className="cp-mr-8" onClick={close}>
                    Done
                  </CpButton>
                  <CpButton
                    icon="add-small"
                    btnType="flat"
                    onClick={() => {
                      onClose();
                      close();
                    }}
                  >
                    Create custom signer
                  </CpButton>
                </>
              )
            : null
        }
      />
    </>
  );
};
