import React from "react";
import PropTypes from "prop-types";

import styles from "../client-request/client-request.styles.css";
import Title from "../client-request/title/title.component.js";
import DueDate from "../client-request/due_at/due-date.component.js";
import Reminders from "../client-request/reminders/reminders.component.js";
import DescriptionRTE from "../client-request/description/description.component.js";
import { userMatchesSigningLocation } from "../signing-modal.helper";
import { CpButton, CpInlineNotification, CpLoader } from "canopy-styleguide!sofe";
import { getKbaCredits } from "../kba/kba.resource";
import { warningToast } from "toast-service!sofe";
import moment from "moment";
import { isEqual, trim } from "lodash";
import { handleError } from "src/handle-error.helper";

export default class EsignRequest extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    signingRequestIsValid: PropTypes.bool.isRequired,
    finalizeEsignExperience: PropTypes.func.isRequired,
    kbaSigning: PropTypes.bool,
    showClientRequestCreatedSuccessfullyModal: PropTypes.bool,
    updateKbaCredits: PropTypes.func,
    creditsSubtracted: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      reminderModalDisplayed: false,
      loading: false,
      chosenDescription: null,
      clientCollaborators: null,
      filteredCollaborators: [],
    };
  }

  componentDidMount() {
    this.filterEsignInvites();
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.signersContext, this.props.signersContext) ||
      !isEqual(prevProps.signingLocations, this.props.signingLocations)
    ) {
      this.filterEsignInvites();
    }
    if (!isEqual(prevProps.esignContext, this.props.esignContext)) {
      this.props.setEsignContext({ ...this.props.esignContext });
    }
  }

  render() {
    const { chosenDescription, loading, reminderModalDisplayed } = this.state;

    const { title, due_at, reminders, description } = this.props.esignContext;

    const allDateFields =
      this.props.signingLocations?.length > 0 &&
      this.props.signingLocations?.every((signingLocation) => signingLocation.type === "date");

    return (
      <div className={styles.clientRequestContainer}>
        <div>
          <div className={`${styles.clientRequestElementLight} cps-body`}>
            <Title setTitle={this.setTitle} title={title} />
          </div>
          <div className={styles.clientRequestElementLight}>
            <DueDate setDueDate={this.setDueDate} removeDueDate={this.removeDueDate} date={due_at} />
          </div>
          <div className={styles.clientRequestElementLight}>
            <Reminders
              reminders={reminders}
              setReminderModalDisplayed={this.setReminderModalDisplayed}
              setReminder={this.setReminder}
              removeReminder={this.removeReminder}
              reminderModalDisplayed={reminderModalDisplayed}
              chosenDescription={reminders?.reminder_interval ? chosenDescription : null}
            />
          </div>
          <div className={styles.clientRequestElementLight}>
            <DescriptionRTE description={description} updateDescriptionRTE={this.updateDescriptionRTE} />
          </div>
        </div>
        <div className={`${styles.clientRequestSubmit} ${styles.clientRequestElementLight}`}>
          <CpButton
            btnType="primary"
            block
            disabled={allDateFields || !this.isValidEsignRequest()}
            onClick={this.sendClientPortalInvites}
          >
            {loading === true ? <CpLoader /> : "Send now"}
          </CpButton>
          {allDateFields && (
            <div className="cp-pt-24">
              <CpInlineNotification message="Please add a signature or initial field in order to send the request." />
            </div>
          )}
        </div>
      </div>
    );
  }

  filterEsignInvites = () => {
    const filteredCollaboratorsList = this.props.signersContext?.filter((user) =>
      this.props.signingLocations?.find(
        (location) => userMatchesSigningLocation(location, user) && location.signatory_user_id
      )
    );
    this.setState({ filteredCollaborators: filteredCollaboratorsList });
  };

  setTitle = (event) => {
    this.props.setEsignContext({ ...this.props.esignContext, title: event.target.value });
  };

  setDueDate = (due_at) => {
    this.props.setEsignContext({ ...this.props.esignContext, due_at });
  };

  removeDueDate = () => {
    this.props.setEsignContext({ ...this.props.esignContext, due_at: null });
  };

  setReminderModalDisplayed = (reminderModalDisplayed = false) => {
    this.setState({ reminderModalDisplayed });
  };

  setReminder = (reminder_interval, expiration_interval_days, descriptionObj) => {
    this.setState({ chosenDescription: descriptionObj });
    this.setReminderModalDisplayed(false);
    this.props.setEsignContext({
      ...this.props.esignContext,
      reminders: { reminder_interval, expiration_interval_days },
    });
  };

  removeReminder = () => {
    this.props.setEsignContext({
      ...this.props.esignContext,
      reminders: { reminderinterval: null, expiration_interval_days: null },
    });
  };

  updateDescriptionRTE = (description) => {
    this.props.setEsignContext({ ...this.props.esignContext, description });
  };

  sendClientPortalInvites = () => {
    //TODO: add users to send invite modal
    const invites = this.state.filteredCollaborators.filter((user) => user.sendClientPortalInvite);
    if (invites?.length) {
      SystemJS.import("clients-ui!sofe").then((clients) =>
        clients.showInviteClientModal(
          this.props.clientId,
          this.submit, //(users)
          undefined,
          false,
          false,
          false,
          "Cancel",
          false,
          undefined,
          invites
        )
      );
    } else {
      this.submit();
    }
  };

  submit = (invitesSent = false) => {
    this.setState({ loading: true });
    const { due_at, reminders, title, description } = this.props.esignContext;
    const due_date = due_at ? moment(due_at).format("YYYY-MM-DD") : due_at;
    const reminder_data =
      reminders && reminders.expiration_interval_days
        ? {
            expiration_interval_days: parseInt(reminders.expiration_interval_days, 10),
            interval: reminders.reminder_interval,
          }
        : null;
    const esignDoc = {
      title: trim(title),
      due_date,
      description,
      kba_enabled: this.props.kbaSigning,
    };
    reminder_data ? (esignDoc.reminder_data = reminder_data) : null;
    if (this.props.documentType === "letter") {
      this.props.finalizeSigningExperience(esignDoc);
      return;
    }
    this.props.finalizeEsignExperience(esignDoc, invitesSent, (insufficientCredits) => {
      getKbaCredits().subscribe(({ qty_available, qty_used }) => {
        this.setState({ loading: false });

        if (insufficientCredits) {
          this.props.updateKbaCredits(qty_available, qty_used);
          warningToast(`Insufficient credits`);
        }
      }, handleError);
    });
  };

  isValidEsignRequest = () => {
    return (
      this.props.signingRequestIsValid &&
      this.props.esignContext.title.length &&
      !this.props.showClientRequestCreatedSuccessfullyModal
    );
  };
}
