import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { getKbaCredits } from "../../kba/kba.resource";
import EsignRequest from "../esign-request.component.js";
import DraggableMenuItem from "src/signing-items/draggable-menu-item.component.js";
import Kba from "../../kba/credits/kba.component";
import { CpIcon } from "canopy-styleguide!sofe";
import { handleError } from "src/handle-error.helper";

import styles from "../esign-modal.styles.css";
import Disposable from "react-disposable-decorator";
import { EsignContext, SigningContext } from "../../signing-context";

@Disposable
export default class SidePanel extends React.Component {
  static propTypes = {
    tabs: PropTypes.object.isRequired,
    activeTab: PropTypes.string.isRequired,
    signingId: PropTypes.string,
    resolutionCaseId: PropTypes.string,
    title: PropTypes.string.isRequired,
    signingRequestIsValid: PropTypes.bool.isRequired,
    clientRequestData: PropTypes.object,
    showClientRequestCreatedSuccessfullyModal: PropTypes.bool.isRequired,
    signatureElements: PropTypes.array.isRequired,
    context: PropTypes.shape({
      loggedInUser: PropTypes.object,
    }),
    setActiveTab: PropTypes.func.isRequired,
    setClientRequestData: PropTypes.func.isRequired,
    finalizeSigningExperience: PropTypes.func.isRequired,
    finalizeEsignExperience: PropTypes.func,
    creditsSubtracted: PropTypes.number,
    setKbaEnabled: PropTypes.func,
    kbaEnabled: PropTypes.bool,
  };

  state = {
    credits: 0,
    creditsUsedToDate: 0,
  };

  componentDidMount() {
    this.retrieveKbaCredits();
  }

  retrieveKbaCredits = () => {
    const { cancelWhenUnmounted } = this.props;
    cancelWhenUnmounted(
      getKbaCredits().subscribe(({ qty_available, qty_used }) => {
        this.updateKbaCredits(qty_available, qty_used);
      }, handleError)
    );
  };

  updateKbaCredits = (credits, creditsUsedToDate) => {
    this.setState((prevState) => {
      return prevState.credits === credits ? false : { credits, creditsUsedToDate };
    });
  };

  renderClientRequest = () => {
    const {
      title,
      signingRequestIsValid,
      finalizeEsignExperience,
      creditsSubtracted,
      signingLocations,
      clientId,
      kbaEnabled,
      documentType,
      finalizeSigningExperience,
    } = this.props;
    const { credits } = this.state;
    const creditsRemaining = credits - creditsSubtracted;
    const hasEnoughCredits = creditsRemaining >= 0;
    // If kba is enabled, check for sufficient credits. Otherwise use signingRequestIsValid
    const kbaSigningRequestIsValid = (!kbaEnabled && signingRequestIsValid) || (kbaEnabled && hasEnoughCredits);

    return (
      <SigningContext.Consumer>
        {({ signersContext }) => (
          <EsignContext.Consumer>
            {({ esignContext, setEsignContext }) => (
              <EsignRequest
                creditsSubtracted={creditsSubtracted}
                clientId={clientId}
                documentType={documentType}
                esignContext={esignContext}
                finalizeEsignExperience={finalizeEsignExperience}
                finalizeSigningExperience={finalizeSigningExperience}
                kbaSigning={kbaEnabled}
                setEsignContext={setEsignContext}
                signersContext={signersContext}
                signingLocations={signingLocations}
                signingRequestIsValid={kbaSigningRequestIsValid}
                title={title}
                updateKbaCredits={this.updateKbaCredits}
              />
            )}
          </EsignContext.Consumer>
        )}
      </SigningContext.Consumer>
    );
  };

  renderDraggableSignatures = () => {
    const { signatureElements, context, activeTab } = this.props;
    return (
      <div className={`${styles.draggableElementsRow}`}>
        <div className={`${styles.draggableElementsContainer}`}>
          {signatureElements.map((signatureElement, pos) => (
            <DraggableMenuItem
              key={pos}
              signatureElement={signatureElement}
              activeTab={activeTab}
              context={context}
              onDropSignature={this.onDropSignature}
              onRemoveSignature={this.onRemoveSignature}
            />
          ))}
        </div>
      </div>
    );
  };

  renderKba = () => {
    const { credits, creditsUsedToDate } = this.state;
    const { tabs, activeTab, creditsSubtracted, kbaEnabled } = this.props;
    const shouldDisplayCredits = (activeTab !== tabs.SIGNATURE && kbaEnabled) || activeTab === tabs.RECIPIENTS;

    return shouldDisplayCredits ? (
      <Kba
        credits={credits}
        setKbaEnabled={this.setKbaEnabled}
        kbaEnabled={kbaEnabled}
        creditsSubtracted={creditsSubtracted}
        creditsUsedToDate={creditsUsedToDate}
      />
    ) : null;
  };

  setKbaEnabled = (enable) => {
    this.props.setKbaEnabled && this.props.setKbaEnabled(enable);
  };

  render() {
    const { activeTab, setActiveTab, tabs } = this.props;

    return (
      <div className={styles.lightColumn}>
        <div className="cp-flex-column justify-between" style={{ height: "100%" }}>
          <div className={styles.flexToTopContainer}>
            <div className={styles.lightTabStrip}>
              {map(tabs, (tab, key) => {
                return (
                  <div
                    key={key}
                    onClick={setActiveTab.bind(null, tab)}
                    className={`${styles.tabStripElementLight} ${activeTab === tab ? styles.selected : null}`}
                  >
                    {tab}
                  </div>
                );
              })}
            </div>

            {this.renderKba()}
            {activeTab !== tabs.REQUEST && this.renderDraggableSignatures()}
            {activeTab === tabs.REQUEST && this.renderClientRequest()}
            {activeTab === tabs.RECIPIENTS && (
              <div className={styles.practitionerInfoContainerLight}>
                <CpIcon name="information-circle-open-small" className={styles.practitionerInfoIcon} />
                <div className={styles.practitionerInfo}>
                  All signers will receive an email with a link to complete the request and will not be required to have
                  a client portal. Client Portal users will also receive an eSign task within their portal.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SidePanel.defaultProps = {
  creditsSubtracted: 0,
};
