import React, { useContext, useState, useMemo } from "react";
import { CpButton, CpInput, CpLabel, CpToggle } from "canopy-styleguide!sofe";
import { trim } from "lodash";
import { EsignContext } from "../../../signing-context"; // eslint-disable-line no-unused-vars
import DatePicker from "../../../client-request/due_at/date-picker.component";

import { templateTypes } from "../../../constants";
import NewReminders from "../../../client-request/reminders/newReminders.component";
import DescriptionRTE from "../../../client-request/description/description.component";
import { SendButton } from "./send-button.component";
import { CreateTemplateButton } from "./create-template-button.component";
import { KbaWell } from "../../../kba/credits/kba-well.component";

export const SettingsTab = ({
  isTemplateMode,
  kbaEnabled,
  setKbaEnabled,
  creditsSubtracted,
  esignTemplateAccess,
  setHasEnoughKbaCredits,
  ...props
}) => {
  const { esignContext, setEsignContext } = useContext(EsignContext);
  const [reminderModalDisplayed, setReminderModalDisplayed] = useState(false);
  const [chosenDescription, setChosenDescription] = useState(null);
  const isPreset = esignContext?.template_type === templateTypes.preset?.id;

  const setField = (field, value) => {
    setEsignContext({ ...esignContext, [field]: value });
  };

  const addReminder = (reminder_interval, expiration_interval_days, descriptionObj) => {
    setChosenDescription(descriptionObj);
    setReminderModalDisplayed(false);
    setField("reminders", { reminder_interval, expiration_interval_days });
  };

  const errorMessage = useMemo(() => {
    if (!esignTemplateAccess) return;
    if (!trim(esignContext.template_name)) return "Template title is required.";
  }, [esignContext.template_name]);

  const toggleTemplateType = (checked) => {
    if (checked) setField("template_type", "team");
    if (!checked) setField("template_type", "private");
  };

  const descriptionError = useMemo(() => {
    return esignContext.description.length > 1024 ? "Maximum character limit exceeded." : null;
  }, [esignContext.description]);

  const trimTemplateTitle = () => {
    const nameToTrim = trim(esignContext.template_name);
    setEsignContext({ ...esignContext, template_name: nameToTrim });
  };

  return (
    <>
      {isTemplateMode && (
        <div className="cp-mb-16">
          <CpInput
            label="Template title"
            maxLength={64}
            required
            value={esignContext?.template_name}
            error={errorMessage}
            onChange={(value) => setField("template_name", value)}
            onBlur={trimTemplateTitle}
          />
        </div>
      )}
      <div className="cp-mb-16">
        <CpInput
          label="Request title"
          className="cp-mb-8"
          maxLength={255}
          value={esignContext.title}
          onChange={(value) => setField("title", value)}
        />
      </div>
      {!isTemplateMode && (
        <>
          <div className="cp-flex-column">
            <CpLabel className="cp-mt-8" htmlFor="esign-datepicker">
              Due date
            </CpLabel>
            <div className="cp-flex-center justify-between">
              <DatePicker date={esignContext.due_at} setDueDate={(value) => setField("due_at", value)} />
              <CpButton
                icon="crud-trash-large"
                disabled={!esignContext.due_at}
                onClick={() => setField("due_at", null)}
              />
            </div>
          </div>
          <NewReminders
            reminders={esignContext.reminders}
            setReminderModalDisplayed={setReminderModalDisplayed}
            setReminder={addReminder}
            removeReminder={() => setField("reminders", { reminder_interval: null, expiration_interval_days: null })}
            reminderModalDisplayed={reminderModalDisplayed}
            chosenDescription={esignContext.reminders?.reminder_interval ? chosenDescription : null}
          />
        </>
      )}

      <CpLabel htmlFor="esign-description" className="cp-mt-16">
        Message
      </CpLabel>
      <DescriptionRTE
        key={esignContext.templateId || "description"}
        description={esignContext.description}
        updateDescriptionRTE={(value) => setField("description", value)}
        descriptionError={descriptionError}
      />
      {isTemplateMode && !isPreset && (
        <div className="cp-flex-column cp-pv-16" style={{ borderBottom: "1px solid var(--cp-color-app-border)" }}>
          <CpLabel htmlFor="esign-template-type" className="cp-mv-8 cp-body cp-color-app-primary-text">
            Team access
          </CpLabel>
          <div className="cp-color-app-secondary-text cp-flex-center justify-between">
            <span>Make visible to all team members</span>
            <CpToggle checked={esignContext.template_type === "team" ? true : false} onChange={toggleTemplateType} />
          </div>
        </div>
      )}
      {!isPreset && (
        <KbaWell
          isTemplateMode={isTemplateMode}
          setKbaEnabled={setKbaEnabled}
          kbaEnabled={kbaEnabled}
          creditsSubtracted={creditsSubtracted}
          setHasEnoughKbaCredits={setHasEnoughKbaCredits}
        />
      )}
      {!isTemplateMode && <SendButton descriptionError={descriptionError} kbaEnabled={kbaEnabled} {...props} />}
      {isTemplateMode && (
        <CreateTemplateButton
          {...props}
          descriptionError={descriptionError}
          kbaEnabled={kbaEnabled}
          isTemplateMode
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};
