import React from "react";
import styles from "./kba-verification-failed.styles.css";
import PropTypes from "prop-types";
import { NUMBERS_MAP } from "./kba-file-upload.helpers";

export default class KbaVerificationFailed extends React.Component {
  static propTypes = {
    retry: PropTypes.func,
    attemptsRemaining: PropTypes.number,
    kbaAttemptsAllowed: PropTypes.number,
  };
  render() {
    return (
      <div className={`cps-margin-top-24 ${styles.failedStateContainer}`}>
        <div className={`${styles.kbaFailedAuthShieldImage}`} />
        <h2 className="cps-wt-bold cps-subheader">Answers Did Not Pass Authentication</h2>
        <span className="cps-body cps-margin-bottom-16">
          After <span className="cps-wt-bold">{NUMBERS_MAP[this.props.kbaAttemptsAllowed]}</span> attempts, the IRS
          requires signers to submit a hand signed document
        </span>
        <div className={`${styles.retryCard} cps-bg-color-background`}>
          <span className="cps-icon cps-icon-refresh cps-color-blue" aria-hidden={true} />
          <span className="cps-body">
            <span className="cps-wt-bold cps-margin-right-4">{this.props.attemptsRemaining}</span> Authentication
            attempt{this.props.attemptsRemaining === 1 ? "" : "s"} remaining
          </span>
          <button onClick={this.props.retry} type="button" className="cps-btn +primary">
            Retry
          </button>
        </div>
      </div>
    );
  }
}
