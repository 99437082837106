import React, { useState, useContext } from "react";
import { CpSelectSingle } from "canopy-styleguide!sofe";
import { signatureElements } from "../../../constants";
import { SignerPill } from "../signer-pill.component";
import PanelDragWrapper from "./panel-drag-wrapper.component";
import { SigningContext } from "../../../signing-context";
import { getContactType } from "../../../signing-modal.helper";

export const FieldsTab = ({ isTemplateMode }) => {
  const { getSelectedSigners, isNewCrm } = useContext(SigningContext);
  const selectedSigners = getSelectedSigners();
  const [selectedSigner, setSelectedSigner] = useState(selectedSigners[0]);
  const isTeamMember = selectedSigner?.user_role === "TeamMember" || selectedSigner?.role === "TeamMember";

  return (
    <div className="min-w-0">
      <CpSelectSingle
        triggerIsBlock
        contentWidth={308}
        data={selectedSigners}
        onChange={setSelectedSigner}
        transformData={(signer) => ({
          ...signer,
          name: `${signer?.isSelf ? "(You) " : ""}${signer.name}`,
          icon: "shape-circle-closed",
          ...(isNewCrm
            ? {
                overline: getContactType(signer),
              }
            : {}),
          iconColor:
            signer?.user_role === "TeamMember" || signer?.role === "TeamMember"
              ? "var(--cp-color-pill-team-text)"
              : "var(--cp-color-default-badge-bg)",
          isTeamMember: signer?.user_role === "TeamMember" || signer?.role === "TeamMember",
        })}
        value={selectedSigner}
      />
      <div className="cp-flex-center flex-wrap cp-mv-16">
        {selectedSigner &&
          signatureElements.map((element) => (
            <React.Fragment key={element.type}>
              <PanelDragWrapper
                signatureElement={element}
                signer={selectedSigner}
                isTeamMember={isTeamMember}
                isTemplateMode={isTemplateMode}
              >
                <SignerPill
                  isTemplateMode={isTemplateMode}
                  text={element.text}
                  dragHandle
                  icon={element.cpIcon}
                  teamMember={isTeamMember}
                  dragSource
                />
              </PanelDragWrapper>
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};
