import React, { lazy, Suspense } from "react";
import { CpButton, CpLoader, CpLabel } from "canopy-styleguide!sofe";

const ReminderPicker = lazy(async () => {
  const { getReminderPicker } = await SystemJS.import("tasks-ui!sofe");
  return await getReminderPicker();
});

export default function NewReminders({ reminders, removeReminder, setReminder, chosenDescription }) {
  return (
    <div className="cp-mt-8">
      <CpLabel htmlFor="esign-reminder-picker">Add a reminder</CpLabel>
      <div className="cp-flex-center justify-between">
        <Suspense fallback={<CpLoader />}>
          <ReminderPicker
            id="esign-reminder-picker"
            reminders={reminders}
            setReminder={setReminder}
            style={{ width: "260px", flexGrow: 1 }}
            placeholder={`– Add reminder –`}
            valid={true}
            description={`${
              reminders.reminder_interval
                ? `${chosenDescription ? chosenDescription.shortDescription : ""} for ${
                    reminders.expiration_interval_days
                  } days`
                : ``
            }`}
          />
        </Suspense>
        <CpButton
          onClick={removeReminder}
          disabled={!reminders || !reminders?.reminder_interval}
          icon="crud-trash-large"
        />
      </div>
    </div>
  );
}
