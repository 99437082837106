import React from "react";
import styles from "./request-sent.styles.css";

const RequestSent = (props) => (
  <div className={`${styles.emptySpace}`}>
    <div className="cps-margin-top-10 cps-margin-top-24" style={{ textAlign: "center" }}>
      <img src="https://cdn.canopytax.com/images/paper-plane.svg" />
    </div>
    <div className="cps-text-center cps-animate-fade">
      <h1 className={`cps-wt-bold cps-header-sm cps-color-gray cps-margin-bottom-4`}>Your Signed File Has been Sent</h1>
      <h2 className="cps-subheader-sm cps-color-gray1 cps-margin-top-4">
        {props.subTitle ? props.subTitle : "Your practitioner will be notified."}
      </h2>
      <div className={`cps-margin-top-24`}>
        <a onClick={() => props.closeSigningExperience("success")} className="cps-btn +primary">
          Finish
        </a>
      </div>
    </div>
  </div>
);

export default RequestSent;
