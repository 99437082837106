import React from "react";
import PropTypes from "prop-types";
import styles from "./kba-auth-questions.styles.css";
import KbaSignerHeader from "./../common/kba-signer-header.component";
import { partial, isEmpty } from "lodash";

export default class KbaQuestions extends React.Component {
  static propTypes = {
    instance: PropTypes.object,
    submitResponses: PropTypes.func,
    onTimerComplete: PropTypes.func,
    kbaTimeLimit: PropTypes.number,
  };
  constructor(props) {
    super(props);

    this.state = {
      currentQuestionIndex: 0,
      selectedAnswer: {},
      answerHistory: [],
    };

    this.TIME_STORAGE_NAME = "kba_questions_start_time";
  }
  componentDidMount() {
    localStorage.setItem(this.TIME_STORAGE_NAME, JSON.stringify(Date.now()));
    this.intervalId = setInterval(this.onIntervalTick, 1000);
  }
  componentWillUnmount() {
    if (localStorage.getItem(this.TIME_STORAGE_NAME)) {
      localStorage.removeItem(this.TIME_STORAGE_NAME);
    }
    clearInterval(this.intervalId);
  }
  nextQuestion = () => {
    const numberOfQuestions = this.props.instance.questions.questions.length;
    if (this.state.currentQuestionIndex + 1 !== numberOfQuestions) {
      this.setState((prevState) => ({
        currentQuestionIndex: prevState.currentQuestionIndex + 1,
        answerHistory: [...prevState.answerHistory, prevState.selectedAnswer],
        selectedAnswer: {},
      }));
    }
  };
  selectAnswer = (selectedAnswerId, questionId) => {
    const selectedAnswer = { answer_id: selectedAnswerId, question_id: String(questionId) };
    this.setState({ selectedAnswer });
  };
  submit = () => {
    clearTimeout(this.timerId);
    this.props.submitResponses([...this.state.answerHistory, this.state.selectedAnswer]);
  };
  onIntervalTick = () => {
    const startTime = JSON.parse(localStorage.getItem(this.TIME_STORAGE_NAME));
    const currentTime = Date.now();

    if (currentTime - startTime >= this.props.kbaTimeLimit * 1000) {
      //Time has run out, KBA attempt has failed
      this.props.onTimerComplete();
      localStorage.removeItem(this.TIME_STORAGE_NAME);
    }
  };
  render() {
    const { questions } = this.props.instance.questions;
    const currentQuestion = questions[this.state.currentQuestionIndex];

    return (
      <div style={{ flex: "1 0 100%" }}>
        <div className={`${styles.questionStatusBanner} cps-subheader-sm cps-color-white cps-bg-color-blue`}>
          <span className="cps-icon cps-icon-survey cps-color-white" aria-hidden={true} />
          <span className="cps-padding-left-16 cps-padding-right-16">Question</span>
          <span className="cps-wt-bold">
            {this.state.currentQuestionIndex + 1} of {questions.length}
          </span>
        </div>
        <div className="cps-padding-16">
          <KbaSignerHeader />
          <div className="cps-body cps-margin-bottom-16 cps-wt-semibold">{currentQuestion.question}</div>
          <form>
            {currentQuestion.answers.map((answerObject) => (
              <label
                onClick={partial(this.selectAnswer, answerObject.id, currentQuestion.id)}
                className={`${styles.answerBox} cps-padding-left-16 cps-margin-bottom-8 cps-radio`}
                key={`${currentQuestion.id}${answerObject.id}`}
                data-testid="answer-box"
              >
                <input type="radio" value={answerObject.id} name="kbaAnswer" />
                <span className={`${this.state.selectedAnswer.answer_id === answerObject.id ? "cps-wt-bold" : ""}`}>
                  {answerObject.answer}
                </span>
              </label>
            ))}
          </form>
          <div className="cps-margin-top-16">
            {questions.length === this.state.currentQuestionIndex + 1 ? (
              <button
                onClick={this.submit}
                type="button"
                className="cps-btn +primary"
                disabled={isEmpty(this.state.selectedAnswer)}
              >
                Submit answers
              </button>
            ) : (
              <button
                onClick={this.nextQuestion}
                type="button"
                className="cps-btn +primary"
                disabled={isEmpty(this.state.selectedAnswer)}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
