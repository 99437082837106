import React from "react";
import styles from "../kba.styles.css";

const LOW_CREDITS = 0;
export default function CreditManageWell({ credits, creditsSubtracted = 0 }) {
  const creditsRemaining = credits - creditsSubtracted;
  const lowCredits = creditsRemaining <= LOW_CREDITS;

  return (
    <div className="cps-margin-top-24">
      <div className={`${styles.credits} ${lowCredits ? styles.lowCredit : ""}`}>
        <span
          className={`cps-color-white cps-wt-bold kba-pill ${styles.kbaPill} ${
            lowCredits ? "cps-bg-color-slate" : "cps-bg-color-blue"
          }`}
        >
          {!lowCredits && <i className={`${styles.shield} ${styles.whiteShield}`}></i>}
          {lowCredits && <i className={`${styles.shield} ${styles.whiteWarningShield}`}></i>}
          <strong className="cps-wt-bold">{creditsSubtracted}</strong> Credits Assigned
        </span>
        <span className={`cps-wt-bold cps-depth-1 kba-pill ${styles.kbaPill} cps-bg-color-bumble credit-remaining`}>
          <strong className="cps-wt-bold">{creditsRemaining}</strong> Remaining
        </span>
      </div>
    </div>
  );
}
