import canopyUrls from "canopy-urls!sofe";
import fetcher, { fetchAsObservable, onPusher } from "fetcher!sofe";
import { formatSigningLocationsDataForPost, createSignatureInfo } from "./signing-resource.helper";
import { userMatchesSigningLocation } from "./signing-modal.helper";
import { noop, isNil, omitBy } from "lodash";
import { handleError } from "src/handle-error.helper.js";
import { pluck, filter, first, switchMap } from "rxjs/operators";

export function createSigningDocs(docId, docType = "letter") {
  const body = {
    relationships: {
      origin: {
        type: docType,
        id: docId,
      },
    },
  };

  return fetchAsObservable(`/api/letters/${docId}/esign_docs`, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
    body: `{"esign_docs": ${JSON.stringify(body)}}`,
  });
}

export function getSigningDocPages(signingId, docId) {
  return fetchAsObservable(`/api/letters/${docId}/esign_docs/${signingId}`);
}

export function postEsignatureForDoc(esign_request, loggedInUserSignature) {
  esign_request.signing_locations = esign_request.signing_locations.map((location) => {
    //Omitting null values since server rejects requests that include them
    if (!userMatchesSigningLocation(location, loggedInUserSignature)) {
      return omitBy(location, isNil);
    }

    const signature = createSignatureInfo(loggedInUserSignature, location);

    return { ...location, ...signature };
  });
  return fetchAsObservable(`/api/docs/esign-docs`, {
    method: "POST",
    body: { esign_request },
  }).pipe(pluck("esign_docs"));
}

export async function getFileEsignSvgs(fileId, isTemplateMode = false) {
  const response = await fetcher(
    `/api/docs/files/${fileId}/esign-svgs${isTemplateMode ? `?for_esign_template=true` : ""}`
  );
  const esignData = await response.json();

  if (esignData.status === "not-started") {
    await Promise.race([isReadyPush("preview", fileId), waitFor(1000)]);
    return await getFileEsignSvgs(fileId);
  } else if (esignData.status === "in-progress") {
    await isReadyPush("esign_svgs", fileId);
    return await getFileEsignSvgs(fileId, isTemplateMode);
  } else if (esignData.status === "ready") {
    return esignData;
  }
  throw new Error("Failed to load eSign for this document");
}

function isReadyPush(channel, fileId) {
  return onPusher(channel)
    .pipe(
      filter((r) => r.status === "ready" && r.file_id === fileId),
      first()
    )
    .toPromise();
}

function waitFor(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getDownloadUrl(esign_doc_id) {
  return fetchAsObservable(`/api/docs/esign-docs/${esign_doc_id}/completed-download-url`);
}

export function getClientCollaborators(clientId) {
  return fetchAsObservable(`/api/clients/${clientId}?include=users,clients,client_for,contacts`);
}

export function createSigningLocations(docId, signingDocId, signingLocations) {
  const formattedSigningLocations = formatSigningLocationsDataForPost(signingLocations);
  return fetchAsObservable(`/api/letters/${docId}/esign_docs/${signingDocId}/signing_requests`, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
    body: `{"signing_requests": { "signing_locations": ${JSON.stringify(formattedSigningLocations)}}}`,
  });
}

export function getSigningLocations(docId, signingDocId) {
  return fetchAsObservable(`/api/letters/${docId}/esign_docs/${signingDocId}/signing_requests`);
}

export function signAllUserLocations(
  docId,
  signingDocId,
  signingLocations,
  loggedInUserSignature,
  signerId = null,
  closeModal,
  preAuthSigner,
  resetEsignSent
) {
  const isEsignRequest = !signingDocId;
  if (isEsignRequest) {
    signFileLocations(docId, signingLocations, signerId).subscribe(
      () => {
        if (preAuthSigner) closeModal("success");
        noop();
      },
      (error) => {
        resetEsignSent();
        if (preAuthSigner) closeModal(error);
        handleError(error);
      }
    );
  } else {
    signingLocations
      .filter(
        (signingLocation) =>
          userMatchesSigningLocation(signingLocation, loggedInUserSignature) && !signingLocation.value
      )
      .forEach((signingLocation) => {
        let signature = createSignatureInfo(loggedInUserSignature, signingLocation);

        signLetterLocation(docId, signingDocId, signingLocation.id, signature).subscribe(noop, handleError);
      });
  }
}

export function signLetterLocation(docId, signingDocId, signingLocationId, signature) {
  return fetchAsObservable(
    `/api/letters/${docId}/esign_docs/${signingDocId}/signing_requests/signatures/${signingLocationId}`,
    {
      method: "PUT",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: `{"signatures": ${JSON.stringify(signature)}}`,
    }
  );
}

export function signFileLocations(esign_doc_id, esignature_data, signerId = null) {
  return fetchAsObservable(`/api/docs/esign-docs/${esign_doc_id}/esign${signerId ? `?signer_id=${signerId}` : ""}`, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
    body: { esignature_data },
  });
}

export function postUser(user) {
  let body = { users: { ...user, role: "Client" } };
  return fetchAsObservable(`${canopyUrls.getAPIUrl()}/users`, {
    method: "POST",
    body: body,
  }).pipe(
    pluck("users"),
    switchMap((res) =>
      postSigner({ first_name: user.first_name, last_name: user.last_name, client_id: user.client_id, user_id: res.id })
    )
  );
}

export function getSigners(clientId) {
  return fetchAsObservable(`/api/docs/signers?client_id=${clientId}`).pipe(pluck("signers"));
}

export function getSignerById(signerId) {
  return fetchAsObservable(`/api/docs/signers/${signerId}`).pipe(pluck("signer_info"));
}

export function postSigner(signer) {
  const body = { signer_info: signer };
  return fetchAsObservable(`/api/docs/signers`, { method: "POST", body: body }).pipe(pluck("signer_info"));
}

export function patchSigner(signer) {
  const body = { signer_info: { first_name: signer.first_name, last_name: signer.last_name } };
  return fetchAsObservable(`/api/docs/signers/${signer.id}`, {
    method: "PATCH",
    body: body,
  }).pipe(pluck("signer_info"));
}

export function deleteSigner(signerId, clientId) {
  return fetchAsObservable(`/api/docs/signers/${signerId}?client_id=${clientId}`, {
    method: "DELETE",
  });
}

export function getSignerTypes() {
  return fetchAsObservable(`/api/docs/signer-types`).pipe();
}

export function postSignerType(signerType) {
  const body = { signer_type: signerType };
  return fetchAsObservable(`/api/docs/signer-types`, { method: "POST", body: body }).pipe(pluck("signer_type"));
}

export function patchSignerType(id, signerType) {
  const body = { signer_type: signerType };
  return fetchAsObservable(`/api/docs/signer-types/${id}`, { method: "PATCH", body: body }).pipe(pluck("signer_type"));
}

export function deleteSignerType(signerTypeId) {
  return fetchAsObservable(`/api/docs/signer-types/${signerTypeId}`, { method: "DELETE" });
}

export function postEsignTemplate(template) {
  return fetchAsObservable(`/api/docs/esign-templates`, {
    method: "POST",
    body: { esign_template: template },
  }).pipe(pluck("esign_template"));
}

export function patchEsignTemplate(template) {
  return fetchAsObservable(`/api/docs/esign-templates/${template.id}`, {
    method: "PATCH",
    body: { esign_template: template },
  }).pipe(pluck("esign_template"));
}

export function getEsignTemplates(search) {
  const queryString = `${search ? `&search_text=${search}` : ""}`;
  return fetchAsObservable(`/api/docs/esign-templates?paginate=true&page=1&limit=20&draft=false${queryString}`).pipe(
    pluck("esign_templates")
  );
}
