import { convertDateToString, userMatchesSigningLocation } from "./signing-modal.helper";

const LETTERS_Y_OFFSET = 4;

export function translateXtoPDF(x) {
  return x * 0.75;
}

export function translateYtoPDF(y) {
  return (y + LETTERS_Y_OFFSET) * 0.75;
}

export function translateXfromPDF(x) {
  return x * 1.3333;
}

export function translateYfromPDF(y) {
  return y * 1.3333 - LETTERS_Y_OFFSET;
}

export function formatSigningLocationsDataForPost(signingLocations) {
  return signingLocations.map((signingLocation) => {
    return {
      ...signingLocation,
      x: translateXtoPDF(signingLocation.x),
      y: translateYtoPDF(signingLocation.y),
    };
  });
}

export function createSignatureInfo(userSignature, location) {
  return {
    value: getSignatureLocationValue(location.type, userSignature),
    font: location.type === "date" ? "Source Sans Pro" : userSignature.font,
    consented_to_electronic_records: location.consented_to_electronic_records,
  };
}

export function createEsignSignatureInfo(userSignature, locations, user) {
  const signatures = [];
  locations.forEach((location) => {
    const { esigning_location_id, type } = location;
    if (userMatchesSigningLocation(location, user)) {
      signatures.push({
        esigning_location_id,
        value: getSignatureLocationValue(type, userSignature),
        font: userSignature.font,
      });
    }
  });
  return signatures;
}

export function getSignatureLocationValue(type, userSignature) {
  switch (type) {
    case "signature":
      return userSignature.signatureText;
    case "initials":
      return userSignature.initials;
    default:
      return convertDateToString(userSignature.completed_at);
  }
}
