import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import { CpSelectSingle, CpButton } from "canopy-styleguide!sofe";
import { pick, identity } from "lodash";
import KbaSignerHeader from "../common/kba-signer-header.component";
import styles from "./kba-auth-signer.styles.css";
import { states } from "../kba.helper";

export default class KbaAuthSigner extends React.Component {
  static propTypes = {
    signer: PropTypes.object,
    onSubmit: PropTypes.func,
  };

  state = {
    values: {
      name_first: this.props.signer.firstName || "",
      name_last: this.props.signer.lastName || "",
      address_street1: this.props.signer.address1 || "",
      address_street2: this.props.signer.address2 || "",
      address_city: this.props.signer.city || "",
      address_state: this.props.signer.state || "",
      address_postal_code: this.props.signer.postalCode || "",
      birth_date: this.props.signer.birthDate || "",
      document_value: this.props.signer.ssn || "", // SSN
    },
    errors: {
      name_first: false,
      name_last: false,
      address_street1: false,
      address_city: false,
      address_state: false,
      address_postal_code: false,
      birth_date: false,
      document_value: false,
    },
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const values = { ...this.state.values, [name]: value };
    this.setState({ values });
  };
  trim = (val) => {
    return val.replace(/^\s+|\s+$/g, "");
  };
  validateInput = (event) => {
    const { name } = event.target;
    const value = this.trim(event.target.value);
    const isValid = value !== "";
    const errors = { ...this.state.errors, [name]: !isValid };
    const values = { ...this.state.values, [name]: value };
    this.setState({ errors, values });
  };
  hasErrorClass = (error) => (error ? "cps-has-error" : "");
  hasAddressErrorClass = (name) => {
    return this.state.values[name] === "" &&
      (this.state.errors.address_city ||
        this.state.errors.address_postal_code ||
        this.state.errors.address_state ||
        this.state.errors.address_street1)
      ? "cps-has-error"
      : "";
  };
  hasValidData = () => {
    // return true; //This is for development testing (will be deleted)
    const values = { ...this.state.values };
    delete values.address_street2; // address_street2 is not a required field
    return Object.values(values).every((v) => v && v !== "");
  };
  onSubmit = (e) => {
    e.preventDefault();
    const address_state = this.state.values.address_state.key;
    const [birth_month, birth_day, birth_year] = this.state.values.birth_date.split("/").map((d) => parseInt(d, 10));
    const person = { ...this.state.values, birth_day, birth_month, birth_year, address_state };
    delete person.birth_date;

    //Remove spaces and hyphens in document_value
    person.document_value = person.document_value.replace(/-|\s/g, "");

    this.props.onSubmit(person);
  };
  render() {
    const nameError = this.state.errors.name_first || this.state.errors.name_last;
    const addressError = Object.values(
      pick(this.state.errors, ["address_street1", "address_city", "address_state", "address_postal_code"])
    ).some(identity);
    return (
      <div className={`${styles.kbaAuthSigner}`}>
        <KbaSignerHeader />
        <form className="cps-row" onSubmit={this.onSubmit}>
          <div className={this.props.phoneOnly ? "cps-col-xs-12" : ""}>
            <fieldset className="cp-mb-12">
              <legend className={`${nameError ? "cp-color-app-error-text" : ""}`}>
                <span className="cp-color-app-primary-text">*</span>Legal name
              </legend>
              <div className="cps-row">
                <div
                  className={`cps-col-xs-6 cps-form-group cp-mb-0 ${this.hasErrorClass(this.state.errors.name_first)}`}
                >
                  <label htmlFor="kbaSignerFirstName" className="cps-hide-visually">
                    First name
                  </label>
                  <input
                    type="text"
                    className="cps-form-control"
                    id="kbaSignerFirstName"
                    name="name_first"
                    placeholder="First"
                    autoFocus
                    value={this.state.values.name_first}
                    onChange={this.handleChange}
                    onBlur={this.validateInput}
                    aria-invalid={this.state.errors.name_first}
                  />
                </div>
                <div
                  className={`cps-col-xs-6 cps-form-group cp-mb-0 ${this.hasErrorClass(
                    this.state.errors.name_first || this.state.errors.name_last
                  )}`}
                >
                  <label htmlFor="kbaSignerLastName" className="cps-hide-visually">
                    Last name
                  </label>
                  <input
                    type="text"
                    className="cps-form-control"
                    id="kbaSignerLastName"
                    name="name_last"
                    placeholder="Last"
                    value={this.state.values.name_last}
                    onChange={this.handleChange}
                    onBlur={this.validateInput}
                    aria-invalid={this.state.errors.name_first || this.state.errors.name_last}
                  />
                </div>
                {nameError && (
                  <span className="cp-ml-16 cp-color-app-error-text cp-caption" role="alert">
                    Legal name is required
                  </span>
                )}
              </div>
            </fieldset>
            <fieldset className="cp-mb-12">
              <legend className={`${addressError ? "cp-color-app-error-text" : ""}`}>
                <span className="cp-color-app-primary-text">*</span>Address
              </legend>
              <div className={`cps-form-group ${this.hasAddressErrorClass("address_street1")}`}>
                <label htmlFor="kbaSignerAddressLine1" className="cps-hide-visually">
                  Address line 1
                </label>
                <input
                  type="text"
                  className="cps-form-control"
                  id="kbaSignerAddressLine1"
                  name="address_street1"
                  placeholder="Street 1"
                  value={this.state.values.address_street1}
                  onChange={this.handleChange}
                  onBlur={this.validateInput}
                  aria-invalid={this.state.errors.address_street1}
                />
              </div>
              <div className="cps-form-group">
                <label htmlFor="kbaSignerAddressLine2" className="cps-hide-visually">
                  Address line 2
                </label>
                <input
                  type="text"
                  className="cps-form-control cp-mt-8"
                  id="kbaSignerAddressLine2"
                  name="address_street2"
                  placeholder="Street 2"
                  value={this.state.values.address_street2}
                  onChange={this.handleChange}
                />
              </div>
              <div className="cps-row cp-mt-8">
                <div className={`cps-form-group cps-col-xs-5 cp-mb-0 ${this.hasAddressErrorClass("address_city")}`}>
                  <label htmlFor="kbaSignerCity" className="cps-hide-visually">
                    City
                  </label>
                  <input
                    type="text"
                    className="cps-form-control"
                    id="kbaSignerCity"
                    name="address_city"
                    placeholder="City"
                    value={this.state.values.address_city}
                    onChange={this.handleChange}
                    onBlur={this.validateInput}
                    aria-invalid={this.state.errors.address_city}
                  />
                </div>
                <div className="cps-col-xs-3">
                  <CpSelectSingle
                    data={states}
                    transformData={(item) => ({
                      id: item.key,
                      name: item.value,
                      ...item,
                    })}
                    contentWidth="sm"
                    value={this.state.values.address_state}
                    triggerIsBlock
                    onChange={(value) => {
                      const evt = {
                        target: {
                          name: "address_state",
                          value,
                        },
                      };
                      this.handleChange(evt);
                    }}
                    error={this.state.errors.address_state}
                    placeholder="State"
                  />
                </div>
                <div
                  className={`cps-form-group cps-col-xs-4 cp-mb-0 ${this.hasAddressErrorClass("address_postal_code")}`}
                >
                  <label htmlFor="kbaSignerZip" className="cps-hide-visually">
                    Zip code
                  </label>
                  <input
                    type="text"
                    className="cps-form-control"
                    id="kbaSignerZip"
                    placeholder="Zip"
                    name="address_postal_code"
                    value={this.state.values.address_postal_code}
                    onChange={this.handleChange}
                    onBlur={this.validateInput}
                    aria-invalid={this.state.errors.address_postal_code}
                  />
                </div>
                {addressError && (
                  <span className="cp-ml-16 cp-color-app-error-text cp-caption" role="alert">
                    Valid address is required
                  </span>
                )}
              </div>
            </fieldset>
            <div className="cps-row">
              <div className={`cps-form-group cps-col-xs-6 ${this.hasErrorClass(this.state.errors.birth_date)}`}>
                <label htmlFor="kbaSignerBirthdate" className="cps-body-sm">
                  <span className="cp-color-app-primary-text">*</span>Birthdate
                </label>
                <MaskedInput
                  className="cps-form-control"
                  id="kbaSignerBirthdate"
                  type="text"
                  name="birth_date"
                  placeholder="mm/dd/yyyy"
                  mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                  placeholderChar={"\u2000"}
                  value={this.state.values.birth_date}
                  onChange={this.handleChange}
                  onBlur={this.validateInput}
                  aria-invalid={this.state.errors.birth_date}
                />
                {this.state.errors.birth_date && (
                  <span className="cp-ml-8 cp-color-app-error-text cp-caption" role="alert">
                    Valid birthdate is required
                  </span>
                )}
              </div>
              <div className={`cps-form-group cps-col-xs-6 ${this.hasErrorClass(this.state.errors.document_value)}`}>
                <label htmlFor="kbaSignerSSN" className="cps-body-sm">
                  <span className="cp-color-app-primary-text">*</span>SSN
                </label>
                <MaskedInput
                  className="cps-form-control"
                  id="kbaSignerSSN"
                  type="text"
                  name="document_value"
                  placeholder="XXX-XX-XXXX"
                  mask={[/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                  placeholderChar={"\u2000"}
                  value={this.state.values.document_value}
                  onChange={this.handleChange}
                  onBlur={this.validateInput}
                  aria-invalid={this.state.errors.document_value}
                />
                {this.state.errors.document_value && (
                  <span className="cp-ml-8 cp-color-app-error-text cp-caption" role="alert">
                    Valid SSN is required
                  </span>
                )}
              </div>
            </div>
            <div className="cp-mt-24">
              <CpButton btnType="primary" type="submit" disabled={!this.hasValidData()}>
                Begin
              </CpButton>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

KbaAuthSigner.defaultProps = {
  signer: {},
};
