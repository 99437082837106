import React, { useContext } from "react";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { useDrop, useDragLayer } from "react-dnd";
import { dragTypes, signingFieldTypes, esignTemplatesFeatureBubble } from "../constants";
import { dropSigningField } from "./document-view.component";
import { useCss, k } from "kremling";
import { CpIcon } from "canopy-styleguide!sofe";
import { SignerPill } from "../esign/page-layout-components/signer-pill.component";
import { SigningContext } from "../signing-context";
import { useFeatureBubble } from "feature-toggles!sofe";

export const DocumentDropWrapper = (props) => {
  const showEsignTemplates = useFeatureBubble(esignTemplatesFeatureBubble);

  const [, drop] = useDrop(
    () => ({
      accept: [dragTypes.DRAG_FIELD, dragTypes.SIGNING_FIELD],
      drop(_item, monitor) {
        const didDrop = monitor.didDrop();
        if (didDrop) return;
        return dropSigningField(_item, monitor, { ...props, showEsignTemplates });
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [props.children]
  );
  return <div ref={drop}>{React.cloneElement(props.children)}</div>;
};

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const getItemStyles = (currentOffset) => {
  if (!currentOffset) {
    return {
      display: "none",
    };
  }
  const { x, y } = currentOffset;

  return {
    transform: `translate(${x}px, ${y}px)`,
    filter: isSafari ? "none" : "drop-shadow(0 2px 12px rgba(0,0,0,0.45))",
  };
};

export const DragLayer = ({ showEsignTemplates }) => {
  const scope = useCss(css);
  const [user] = useWithUserAndTenant();

  const { signerTypesContext, signersContext } = useContext(SigningContext);

  const { itemType, isDragging, item, currentOffset, pointerOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(), //the offset of the drag item
    pointerOffset: monitor.getClientOffset(), //the offset of the pointer event
    isDragging: monitor.isDragging(),
  }));

  if (!item) return null;

  const isMySignature = user.id === item?.signatory_user_id;
  const isSignatureObject = item?.type === signingFieldTypes.SIGNATURE;
  const isInitialObject = item?.type === signingFieldTypes.INITIAL;
  const itemID = item.signer_type_id || item.signatory_user_id;

  const assignedTo = [...signerTypesContext, ...signersContext].find((signer) => signer.id === itemID);
  const isTeamMember = assignedTo?.role === "TeamMember" || assignedTo?.user_role === "TeamMember";

  if (!isDragging) {
    return null;
  }
  if (item.value && item.completed_at) return null;
  let itemText = item?.text;
  if (isMySignature) itemText = !isSignatureObject ? (isInitialObject ? "Initial" : "Date") : "My Signature";
  if (!isMySignature)
    itemText = isSignatureObject
      ? item?.name || item?.signer_type_name || "Recipient Signature"
      : isInitialObject
      ? "Initial"
      : "Date";
  if (!isMySignature && !isSignatureObject && item?.name) itemText += `: ${item?.name}`;

  return (
    <div {...scope} className="drag-layer-wrapper">
      <div style={getItemStyles(itemType === "dragField" ? pointerOffset : currentOffset)}>
        {!showEsignTemplates ? (
          <DragPreview type={itemType} item={item} itemText={itemText} />
        ) : (
          <PillDragPreview teamMember={isTeamMember} type={itemType} item={item} itemText={itemText} />
        )}
      </div>
    </div>
  );
};

const DragPreview = ({ item, itemText }) => {
  return (
    <div className="drag-layer">
      <div
        className="drag-layer-drag-box cp-ellipsis"
        style={{
          backgroundColor: `${item?.color || "var(--cp-color-pill-team-bg)"}`,
          border: `2px solid ${item?.border || "var(--cp-color-pill-team-text)"}`,
          opacity: "0.8",
          color: `${item?.border || "var(--cp-color-pill-team-text)"}`,
        }}
      >
        <CpIcon name="misc-drag-handle" />
        {itemText}
      </div>
    </div>
  );
};

const PillDragPreview = ({ item, itemText, teamMember }) => (
  <div className="drag-layer">
    <SignerPill
      dragHandle
      icon={item.cpIcon}
      className="drag-layer-drag-pill"
      teamMember={teamMember}
      text={itemText}
    />
  </div>
);

const css = k`
  .drag-layer-wrapper {
    position: fixed;
    pointer-events: none;
    z-index: 10000;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
   
  }
  .drag-layer-drag-box {
    border-radius: 5px;
    padding: 8px 8px 8px 0;
    position: fixed;
 
    height: 42px;
    width: 160px
  }

  .drag-layer-drag-pill {
    position: fixed;
  }
  
  .drag-layer {
    position: fixed;
    pointer-events: none;
    z-index: 1000;
    left: 0;
    top: 0;
  }
`;
