import React from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { signingFieldTypes, signatureFonts } from "../constants.js";
import SignatureEntryCard from "./signature-entry-card.component.js";
import { CpInlineNotification } from "canopy-styleguide!sofe";

export default class SignatureEntryModal extends React.Component {
  static propTypes = {
    shouldShowSignatureEntryModal: PropTypes.func.isRequired,
    setSignature: PropTypes.func.isRequired,
    context: PropTypes.object.isRequired,
    signatureData: PropTypes.object.isRequired,
    signingType: PropTypes.string.isRequired,
    hasConsented: PropTypes.bool.isRequired,
    updateConsent: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      id: props.context.loggedInUser.id,
      user_id: props.context.loggedInUser?.user_id,
      font: props.signatureData.font || signatureFonts[0],
      signatureText: props.signatureData.signatureText || props.context.loggedInUser.name,
      initials: props.signatureData.initials || props.context.loggedInUser.initials,
      completed_at: Date.now(),
      hasConsented: props.hasConsented,
    };
  }

  onConsentChange = (e) => {
    this.props.updateConsent(e);
  };

  disableAddSignature = () => {
    const fieldText =
      this.props.signingType === signingFieldTypes.SIGNATURE ? this.state.signatureText : this.state.initials;
    return !this.props.hasConsented || !fieldText.match(/[\w]+/);
  };

  render() {
    const { signingType, shouldShowSignatureEntryModal, phoneOnly } = this.props;
    const { initials, signatureText } = this.state;
    const isForInitials = signingType === signingFieldTypes.INITIAL;
    const isEmailAddress = /\S+@\S+\.\S+/.test(signatureText);

    return createPortal(
      <div className="cps-modal">
        <div className="cps-modal__screen"></div>
        <div
          className="cps-modal__dialog cps-card"
          style={{ ...(phoneOnly && { left: "auto", transform: "none", width: "100%" }) }}
        >
          <div className="cps-card__header cps-subheader-sm">
            <span>Add Your {isForInitials ? `Initials` : `Signature`}</span>
            <a
              onClick={shouldShowSignatureEntryModal.bind(null, false)}
              className="cps-modal__dialog__close cps-icon cps-icon-close"
            ></a>
          </div>
          {this.props.signAndSend && (
            <div className="cp-ph-16 cp-pt-16">
              <CpInlineNotification
                message="You are required to sign all fields unassigned to you before sending the eSign request."
                type="info"
              />
            </div>
          )}
          <SignatureEntryCard
            selectedFont={this.state.font}
            signatureText={isForInitials ? initials : isEmailAddress ? null : signatureText}
            handleInputChange={this.handleSignatureInputChange.bind(this)}
            handleFontChange={this.setFont.bind(this)}
            updateConsent={this.onConsentChange.bind(this)}
            hasConsented={this.props.hasConsented}
          />
          <div className="cps-modal__dialog__actions">
            <button disabled={this.disableAddSignature()} onClick={this.setSignature} className={`cps-btn +primary`}>
              {this.props.signAndSend ? "Sign and send" : `Add ${isForInitials ? `initials` : `signature`}`}
            </button>
            <a onClick={shouldShowSignatureEntryModal.bind(null, false)} className="cps-link">
              Cancel
            </a>
          </div>
        </div>
      </div>,
      document.body
    );
  }

  setSignature = () => {
    this.props.setSignature(this.state);
  };

  setFont = (font) => {
    this.setState({ font });
  };

  handleSignatureInputChange = (value) => {
    if (value.length <= 35) {
      if (this.props.signingType === signingFieldTypes.INITIAL) {
        this.setState({ initials: value });
      } else {
        this.setState({ signatureText: value });
      }
    }
  };
}
