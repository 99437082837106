exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".signature-entry-modal-styles__signatureFontContainer--1y1b7 {\n  display: flex;\n  display: -webkit-flex;\n  flex-flow: row nowrap;\n  justify-content: center;\n  align-content: stretch;\n  align-items: center;\n}\n\n.signature-entry-modal-styles__signatureFontElement--2HYt6 {\n  height: 48px;\n  width: 105px;\n  border: solid 1px #dfdfdf;\n  border-radius: 5px;\n  flex: 1 1 auto;\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  align-items: center;\n  text-align: center;\n  cursor: pointer;\n}\n\n.signature-entry-modal-styles__signatureFontElement--2HYt6:hover {\n  border: solid 2px #dfdfdf;\n}\n\n.signature-entry-modal-styles__signatureFontElement--2HYt6.signature-entry-modal-styles__selected--36RoR{\n  border: solid 2px var(--cps-color-primary);\n}\n\n.signature-entry-modal-styles__signatureSampleContainer--wCFIV {\n  min-height: 96px;\n  width: 100%;\n  border: solid 1px #dfdfdf;\n  border-radius: 5px;\n  background-color: #F9F9F9;\n  display: flex;\n  display: -webkit-flex;\n  flex-flow: columns nowrap;\n  justify-content: center;\n  align-content: flex-end;\n  align-items: flex-end;\n  margin: 8px auto;\n  padding-top: 8px\n}\n\n.signature-entry-modal-styles__signatureSample--3cBas {\n  flex: 1 1 auto;\n  display: flex;\n  margin-left: 24px;\n  margin-right: 24px;\n  padding-bottom: 4px;\n  margin-bottom: 18px;\n  border-bottom: solid 1px #dfdfdf;\n  justify-content: flex-start;\n  align-items: flex-end;\n}\n\n.signature-entry-modal-styles__signatureSampleIcon--3U9N1 {\n  position: relative;\n  left: -5px;\n}\n\n.signature-entry-modal-styles__signatureSampleText--3373u {\n  font-size: 24px;\n  margin-bottom: -4px;\n  padding-right: 8px;\n}\n", ""]);
// Exports
exports.locals = {
	"signatureFontContainer": "signature-entry-modal-styles__signatureFontContainer--1y1b7",
	"signatureFontElement": "signature-entry-modal-styles__signatureFontElement--2HYt6",
	"selected": "signature-entry-modal-styles__selected--36RoR",
	"signatureSampleContainer": "signature-entry-modal-styles__signatureSampleContainer--wCFIV",
	"signatureSample": "signature-entry-modal-styles__signatureSample--3cBas",
	"signatureSampleIcon": "signature-entry-modal-styles__signatureSampleIcon--3U9N1",
	"signatureSampleText": "signature-entry-modal-styles__signatureSampleText--3373u"
};