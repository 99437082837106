import React from "react";
import PropTypes from "prop-types";
import { findIndex, cloneDeep } from "lodash";
import { CpContextDropdown } from "canopy-styleguide!sofe";
import styles from "../signing-modal.styles.css";
import { dragTypes, signingFieldTypes } from "../constants.js";
import DraggableSigningField from "../signing-items/draggable-signing-field.component.js";
import NonDraggableSigningField from "../signing-items/non-draggable-signing-field.component.js";
import SelectSignerNewModal from "../signing-items/select-signer-new-modal.component";
import PdfPage from "../document/pdf-page.component";
import { SigningContext } from "../signing-context";
import { DocumentDropWrapper } from "./drop-wrapper.component";
import { featureEnabled } from "feature-toggles!sofe";
import { DragWrapper } from "../signing-items/drag-field-wrapper.component";
import NewDraggableSigningField from "../signing-items/new-draggable-field.component";
import { SigningObjects } from "../signing-items/signing-fields.component";
import { v4 as uuid } from "uuid";

export const dropSigningField = (item, monitor, props) => {
  //List of signingObjects
  const signingObjects = [...props.signingLocations];
  //Creates/Moves the dropped signingObjects
  const signingObject = buildSigningObject(monitor, props);
  props.shouldShowSelectSignerModal(false, signingObject);
  if (signingObject.id) {
    //Replace signingObjects
    const index = findIndex(signingObjects, (item) => item.id === signingObject.id);
    signingObjects[index] = signingObject;
    props.setSigningLocations(signingObjects);
    props.setSigningField(signingObject);
    !props.showEsignTemplates &&
      !signingObject.signatory_user_id &&
      props.shouldShowSelectSignerModal(true, signingObject, {
        x: signingObject.menuX,
        y: signingObject.menuY,
      });
  } else {
    //Create new signingObjects
    const newSigningObject = { ...signingObject, id: uuid() };
    signingObjects.push(newSigningObject);
    props.setSigningLocations(signingObjects);
    const isLoggedInUser = (id, props) => id === props.context.loggedInUser.id;
    //Open related Modal
    if (!isLoggedInUser(signingObject.signer_id ? signingObject.signer_id : signingObject.signatory_user_id, props)) {
      //Show select signer modal if this is for a recipient
      !props.showEsignTemplates &&
        props.shouldShowSelectSignerModal(true, newSigningObject, {
          x: signingObject.menuX,
          y: signingObject.menuY,
        });
    } else if (signingObject.type !== signingFieldTypes.DATE && !props.loggedInUserSignature.signatureText) {
      //Show Signing modal if this was the first 'My Signature' item dropped
      const currentUserSignatureFields = signingObjects.filter(
        ({ signatory_user_id, signer_id, type }) =>
          isLoggedInUser(signer_id ? signer_id : signatory_user_id, props) && type === signingObject.type
      );
      if (currentUserSignatureFields && currentUserSignatureFields.length === 1) {
        !props.showEsignTemplates && props.shouldShowSignatureEntryModal(true, signingObject.type);
      }
    }
  }
};

/**
 * Helper function that builds the new signingObject
 */
function buildSigningObject(monitor, props) {
  //data from item that was dropped
  const draggedItemData = monitor.getItem();
  //Drop Coordinates
  const signingObjectCoordinates = calculateSigningObjectCoordinates(monitor, props.page);

  return { ...draggedItemData, ...signingObjectCoordinates, page: props.page };
}

/**
 * Calculates the coordinates for signingField placement on page
 */
function calculateSigningObjectCoordinates(monitor, page) {
  const itemType = monitor.getItemType();
  const isSigningField = itemType === dragTypes.SIGNING_FIELD;
  //Difference between cursor and element being dragged
  const startDragCursorLocation = monitor.getInitialClientOffset();
  const startDragItemLocation = monitor.getInitialSourceClientOffset();
  const xStartDragOffset = startDragCursorLocation.x - startDragItemLocation.x;
  const yStartDragOffset = startDragCursorLocation.y - startDragItemLocation.y;

  //DropZone location
  const dropAreaElement = document.getElementById(`pageView${page}`);
  const dropAreaLocation = dropAreaElement.getBoundingClientRect();

  //Padding calculations will change when we switch to .SVG
  const dropAreaStyles = dropAreaElement.currentStyle || window.getComputedStyle(dropAreaElement);
  const dropAreaPaddingLeft = parseInt(dropAreaStyles.paddingLeft, 10) || 0;
  const dropAreaPaddingTop = parseInt(dropAreaStyles.paddingTop, 10) || 0;

  const endDragCursorLocation = monitor.getClientOffset();

  let x = endDragCursorLocation.x - dropAreaLocation.left - dropAreaPaddingLeft;
  x = isSigningField ? x - xStartDragOffset : x;

  let y = endDragCursorLocation.y - dropAreaLocation.top - dropAreaPaddingTop;
  y = isSigningField ? y - yStartDragOffset : y;

  let menuX = endDragCursorLocation.x;
  menuX = isSigningField ? menuX - xStartDragOffset : menuX;

  let menuY = endDragCursorLocation.y;
  menuY = isSigningField ? menuY - yStartDragOffset : menuY;

  // min/max for keeping element on the page
  x = x >= 0 ? x : 0;
  x = x <= 780 ? x : 780;
  y = Math.abs(y);

  return { x, y, menuX, menuY };
}

export default class DocumentView extends React.Component {
  static propTypes = {
    offset: PropTypes.object,

    signingLocationsAreDraggable: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    loggedInUserSignature: PropTypes.object.isRequired,
    shouldShowSignatureEntryModal: PropTypes.func.isRequired,
    signingSVG: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    signingLocations: PropTypes.array.isRequired,
    setSigningLocations: PropTypes.func.isRequired,
    clientCollaborators: PropTypes.object.isRequired,
    isClient: PropTypes.bool.isRequired,
    openClientModal: PropTypes.func.isRequired,
    setDocumentSize: PropTypes.func.isRequired,
    onDropSignature: PropTypes.func,
    onRemoveSignature: PropTypes.func,
    displayShield: PropTypes.bool,
    modalMode: PropTypes.bool,
    onSignatureClick: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showSelectSignerModal: false,
      signingField: null,
    };
    this.imgRef = React.createRef();
    this.dropdownRef = React.createRef();
    this.documentRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.handleAddPageRef) {
      this.props.handleAddPageRef({ ref: this.documentRef, page: this.props.page });
    }
  }

  render() {
    const {
      clientCollaborators,
      clientId,
      context,
      documentSizes,
      page,
      phoneOnly,
      primaryClientClientPortalUsers,
      setDocumentSize,
      signingLocations,
      signingSVG,
    } = this.props;
    const { signingField } = this.state;
    const { width, height } = documentSizes?.[page] ? documentSizes?.[page] : { width: 816, height: 1056 };

    return (
      <DocumentDropWrapper
        {...this.props}
        shouldShowSelectSignerModal={this.shouldShowSelectSignerModal.bind(this)}
        setSigningField={(signingField) => this.setState({ signingField })}
      >
        <div>
          <div
            ref={this.documentRef}
            id={`pageView${page}`}
            className={`${styles.document} cps-depth-2`}
            style={{ ...(phoneOnly && { width: "fit-content" }), width: `${width}px`, height: `${height}px` }}
          >
            {this.props.showEsignTemplates && (
              <SigningObjects
                signingLocations={signingLocations.filter((signingObject) => signingObject.page === page)}
                page={page}
                loggedInUserSignature={this.props.loggedInUserSignature}
                signingLocationsAreDraggable={this.props.signingLocationsAreDraggable}
                context={context}
                isClient={this.props.isClient}
                clientCollaborators={this.props.clientCollaborators}
                displayShield={this.props.displayShield}
                modalMode={this.props.modalMode}
                deleteSigningField={this.deleteSigningField}
                showSignerDropdown={this.props.showSignerDropdown}
                preAuthSigner={this.props.preAuthSigner}
                onSignatureClick={this.onSignatureClick}
                isTemplateMode={this.props.isTemplateMode}
                getFilledSignature={this.getFilledSignature}
                shouldShowSelectSignerModal={this.shouldShowSelectSignerModal}
                setSigner={this.setSelectSignerOrType}
                openSignatureEntryModal={(signingObject, objectId) => this.onSignatureClick(signingObject, objectId)}
              />
            )}
            {!this.props.showEsignTemplates && this.renderSigningObjects()}
            {!this.props.showEsignTemplates && (
              <CpContextDropdown
                ref={this.dropdownRef}
                contentWidth={424}
                allowContentClicks={true}
                appendTo={this.documentRef.current}
                onClose={() => this.props.resetSignerDropdown?.()}
                renderContent={() =>
                  this.state.showSelectSignerModal &&
                  !this.props.isTemplateMode && (
                    <SelectSignerNewModal
                      clientCollaborators={clientCollaborators}
                      clientId={clientId}
                      context={context}
                      primaryClientClientPortalUsers={primaryClientClientPortalUsers}
                      resetSigner={this.resetSigner}
                      setSigner={this.setSigner}
                      signingField={signingField}
                      signingLocations={signingLocations}
                    />
                  )
                }
              />
            )}
            {featureEnabled("esign_pdf") ? (
              <PdfPage key={`preview-${page}`} src={signingSVG.url} onLoad={this.onPdfLoad.bind(this)} />
            ) : (
              <img
                src={signingSVG.url}
                ref={this.imgRef}
                onLoad={() => {
                  const { clientWidth: width, clientHeight: height } = this.imgRef.current;
                  this.props.determineDocumentLoaded();
                  setDocumentSize(page, { width, height });
                }}
              />
            )}
          </div>
        </div>
      </DocumentDropWrapper>
    );
  }

  isLoggedInUser = (id) => {
    return id === this.props.context.loggedInUser.id;
  };

  shouldShowSelectSignerModal = (showSelectSignerModal = false, signingField = null, e = null) => {
    if (this.props.showEsignTemplates) return;
    if (showSelectSignerModal === true) {
      const scrollContainer = document.getElementById("docScroll");
      const scrollbarWidth = e?.target ? 0 : scrollContainer.offsetWidth - scrollContainer.clientWidth;
      const rect = e.target
        ? e.target.offsetParent?.id !== "docScroll"
          ? e.target.offsetParent.getBoundingClientRect()
          : e.target.getBoundingClientRect()
        : { top: e.y, left: e.x };
      this.dropdownRef.current.open({
        //the draggable signing fields are all 42px height so this will position the menu exactly below or above
        height: 42,
        //draggable signing object default to 160px min width
        width: rect.width || 160,
        top: scrollContainer.scrollTop - scrollContainer.offsetTop + rect.top,
        left: rect.left - scrollContainer.offsetLeft - scrollbarWidth || 1,
      });
    }
    if (showSelectSignerModal === false && this.dropdownRef.current) {
      this.dropdownRef.current.close();
      this.props.resetSignerDropdown?.();
    }
    this.setState({ showSelectSignerModal, signingField });
  };

  /**
   * Sets the signer name/id for fields that will need to be signed by a collaborator
   */
  setSigner = (clientId, clientName, signingFieldId, signerId = null, role) => {
    const signingObjects = cloneDeep(this.props.signingLocations);
    const index = findIndex(signingObjects, (item) => item.id === signingFieldId);
    if (this.props.isTemplate) {
      this.props.assignSignerTypes(
        { signatory_user_id: clientId, ...(signerId && { signer_id: signerId }), role },
        signingObjects[index].signer_type,
        clientName
      );
      return this.shouldShowSelectSignerModal(false);
    }
    signingObjects[index] = {
      ...signingObjects[index],
      signatory_user_id: clientId,
      ...(signerId && { signer_id: signerId }),
      role,
    };
    !signerId && delete signingObjects[index].signer_id;
    this.props.setSigningLocations(signingObjects, undefined);
    this.shouldShowSelectSignerModal(false);
  };

  setSelectSignerOrType = (selected, signingObject) => {
    const { isTemplateMode } = this.props;
    const signingObjects = cloneDeep(this.props.signingLocations);
    const index = findIndex(signingObjects, (item) => item.id === signingObject.id);
    const isTeamMember = selected.role === "TeamMember";
    if (this.props.isTemplate) {
      this.props.assignSignerTypes(selected, { id: signingObjects[index].signer_type_id });
      return this.shouldShowSelectSignerModal(false);
    }

    signingObjects[index] = {
      ...signingObjects[index],
      ...(!isTemplateMode && { signatory_user_id: selected.user_id || selected.id }),
      ...(!isTemplateMode && !isTeamMember && selected?.user_id && { signer_id: selected.id }),
      ...(isTemplateMode && { signer_type_id: selected.id }),
      ...(isTemplateMode && { signer_type_name: selected.name }),
      role: selected?.role || selected?.user_role || "Client",
      isTeamMember: selected?.role === "TeamMember" || selected?.user_role === "TeamMember",
    };

    !selected.user_id && delete signingObjects[index].signer_id;
    this.props.setSigningLocations(signingObjects, undefined);
    this.shouldShowSelectSignerModal(false);
  };

  resetSigner = (signingFieldId) => {
    const signingObjects = cloneDeep(this.props.signingLocations);
    const index = findIndex(signingObjects, (item) => item.id === signingFieldId);
    if (signingObjects[index]?.signatory_user_id) signingObjects[index].signatory_user_id = null;
    if (signingObjects[index]?.signer_id) signingObjects[index].signer_id = null;
    this.props.setSigningLocations(signingObjects);
    this.shouldShowSelectSignerModal(false);
  };

  markFieldAsSigned = (signingFieldId) => {
    const signingObjects = this.props.signingLocations;

    const index = findIndex(signingObjects, (item) => (item.id || item.esigning_location_id) === signingFieldId);
    signingObjects[index] = { ...signingObjects[index], hasBeenSigned: true };
    this.props.setSigningLocations(signingObjects);
  };

  deleteSigningField = (id) => {
    const signingObjects = this.props.signingLocations;
    const index = findIndex(signingObjects, (item) => item.id === id);
    const uniqueUser = signingObjects[index].signer_id || signingObjects[index].signatory_user_id;
    this.props.onRemoveSignature && this.props.onRemoveSignature(uniqueUser);
    signingObjects.splice(index, 1);
    this.props.setSigningLocations(signingObjects);
    this.shouldShowSelectSignerModal(false);
  };

  onSignatureClick = (signingObject, objectId) => {
    if (this.props.modalMode) {
      this.props.shouldShowSignatureEntryModal(true, signingObject.type, this.markFieldAsSigned.bind(this, objectId));
    } else {
      this.props.onSignatureClick(this.markFieldAsSigned.bind(this, objectId));
    }
  };

  getFilledSignature = (loggedInUserSignature, signingObject) => {
    return this.isLoggedInUser(signingObject.signer_id ? signingObject.signer_id : signingObject.signatory_user_id) &&
      loggedInUserSignature.signatureText
      ? {
          value:
            signingObject.type === signingFieldTypes.SIGNATURE
              ? loggedInUserSignature.signatureText
              : loggedInUserSignature.initials,
          font: loggedInUserSignature.font,
          completed_at: loggedInUserSignature.completed_at,
        }
      : {};
  };

  onPdfLoad = ({ width, height }) => {
    this.props.setDocumentSize({ width, height });
  };

  renderSigningObjects = () => {
    const {
      signingLocations,
      page,
      loggedInUserSignature,
      signingLocationsAreDraggable,
      context,
      shouldShowSignatureEntryModal,
      isClient,
      clientCollaborators,
      displayShield,
      modalMode,
      isTemplateMode,
    } = this.props;
    const DragField = isTemplateMode ? NewDraggableSigningField : DraggableSigningField;

    return signingLocations
      .filter((signingObject) => signingObject.page === page)
      .map((signingObject) => {
        const objectId = signingObject.id || signingObject.esigning_location_id;
        const innerRef = React.createRef();
        signingObject.ref = innerRef;
        const newSigningObject = { ...signingObject, ...this.getFilledSignature(loggedInUserSignature, signingObject) };
        return (
          <SigningContext.Consumer key={objectId}>
            {({ signersContext }) => (
              <>
                {signingLocationsAreDraggable ? (
                  <DragWrapper
                    canDrag={true}
                    signingObject={newSigningObject}
                    clientCollaborators={clientCollaborators}
                    allUsers={signersContext}
                    closeSelectSignerModal={this.shouldShowSelectSignerModal.bind(this, false, signingObject)}
                  >
                    <DragField
                      allUsers={signersContext}
                      canDrag={true}
                      setSigner={this.setSigner}
                      clientCollaborators={clientCollaborators}
                      closeSelectSignerModal={this.shouldShowSelectSignerModal.bind(this, false, signingObject)}
                      context={context}
                      deleteSigningField={this.deleteSigningField}
                      displayShield={displayShield}
                      onClick={(e) => this.shouldShowSelectSignerModal(true, signingObject, e)}
                      openSelectSignerModal={(e, s = null) =>
                        this.shouldShowSelectSignerModal(true, s || signingObject, e)
                      }
                      openSignatureEntryModal={shouldShowSignatureEntryModal}
                      signingObject={newSigningObject}
                      showSignerDropdown={this.props.showSignerDropdown}
                    />
                  </DragWrapper>
                ) : (
                  <NonDraggableSigningField
                    allUsers={signersContext}
                    clientCollaborators={clientCollaborators}
                    context={context}
                    innerRef={innerRef}
                    isClient={isClient}
                    modalMode={modalMode}
                    onClickOfSignature={this.onSignatureClick.bind(this, signingObject, objectId)}
                    preAuthSigner={this.props.preAuthSigner}
                    signingObject={newSigningObject}
                  />
                )}
              </>
            )}
          </SigningContext.Consumer>
        );
      });
  };
}
