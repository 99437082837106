import React, { useEffect } from "react";
// import { useDrag } from "react-dnd";
// import { getEmptyImage } from "react-dnd-html5-backend";
import styles from "./common-signing-field.styles.css";
import { signingFieldTypes } from "../constants.js"; //dragTypes
import { convertDateToString } from "../signing-modal.helper.js";
import { userMatchesSigningLocation } from "../signing-modal.helper";
// import { CpSelectSingle } from "canopy-styleguide!sofe";

export default function DraggableSigningField({
  allUsers,
  clientCollaborators,
  // closeSelectSignerModal,
  signingObject,
  deleteSigningField,
  openSelectSignerModal,
  openSignatureEntryModal,
  context,
  // canDrag,
  displayShield,
  showSignerDropdown,
  isDragging,
}) {
  const signDate = signingObject.completed_at;
  const forCurrentUser = signingObject.signatory_user_id === context.loggedInUser.id;
  const client = allUsers.find((user) => userMatchesSigningLocation(signingObject, user));
  //TODO: do we need the clientCollaborators[signingObject.signatory_user_id] check?
  const clientName = client?.name || clientCollaborators[signingObject.signatory_user_id];
  const isSignatureObject = signingObject.type === signingFieldTypes.SIGNATURE;
  const isInitialObject = signingObject.type === signingFieldTypes.INITIAL;
  const isDateObject = signingObject.type === signingFieldTypes.DATE;
  const draggableRef = React.useRef();

  const dragStyles = {
    opacity: isDragging ? 0 : 1,
  };

  const deleteField = (e) => {
    deleteSigningField(signingObject.id);
    e.stopPropagation();
  };
  useEffect(() => {
    if (showSignerDropdown?.id === signingObject.id) {
      draggableRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      //TODO better track for when scroll ends
      const time = setTimeout(() => {
        openSelectSignerModal({ target: draggableRef.current }, signingObject);
      }, 600);
      return () => clearTimeout(time);
    }
  }, [showSignerDropdown]);

  return signingObject.value && signDate ? (
    <div
      // ref={drag}
      className={`${styles.filledSigningObject}`}
      onClick={() => {
        !isDateObject ? (forCurrentUser ? openSignatureEntryModal(true, signingObject.type) : null) : null;
      }}
      style={{
        ...dragStyles,
        fontFamily: isDateObject ? "Source Sans Pro" : signingObject.font,
        fontSize: isDateObject ? "16pt" : "20pt",
      }}
    >
      {isDateObject ? convertDateToString(signDate) : signingObject.value}
      <DeleteButton onClick={deleteField} className={`${styles.signingObjectDeleteCenterRight}`} />
    </div>
  ) : forCurrentUser ? (
    <div
      // ref={drag}
      className={styles.unfilledSigningObjectGreen}
      onClick={() => {
        !isDateObject ? openSignatureEntryModal(true, signingObject.type) : null;
      }}
      style={dragStyles}
    >
      <div ref={draggableRef} className={styles.dragHandleSmallGreen}></div>
      <DeleteButton onClick={deleteField} className={`cps-bg-accent-green ${styles.signingObjectDeleteTopRight}`} />

      {!isSignatureObject ? (isInitialObject ? "Initial" : "Date") : "My Signature"}
    </div>
  ) : (
    <div
      // ref={drag}
      className={styles.unfilledSigningObjectBlue}
      onClick={(e) => openSelectSignerModal(e)}
      style={dragStyles}
    >
      <div className={styles.dragHandleSmallBlue}></div>
      <DeleteButton onClick={deleteField} className={`cps-bg-primary ${styles.signingObjectDeleteTopRight}`} />

      <div ref={draggableRef} style={{ maxWidth: "150px" }} className={`cp-ellipsis ${styles.signatureContent}`}>
        {isSignatureObject
          ? clientName || signingObject?.signer_type?.name || "Recipient Signature"
          : isInitialObject
          ? "Initial"
          : "Date"}{" "}
        {!isSignatureObject && clientName ? `: ${clientName}` : null}
      </div>
      {displayShield && signingObject.role !== "TeamMember" && <i className={`${styles.shield} ${styles.ocean}`}></i>}
    </div>
  );
}

const DeleteButton = ({ className, onClick }) => (
  <a className={`${styles.signingObjectDeleteButton} ${className}`}>
    <i className="cps-icon cps-white cps-icon-sm-neg" onClick={(event) => onClick(event)}></i>
  </a>
);
